import Vue from 'vue';
import senz from '@/api/senz';
import auth from '@/api/fire/auth';
import fire from '@/api/fire/db';

export default {
  namespaced: true,
  state: {
    playerUrl: process.env.VUE_APP_BASE_PLAYER_URL,
    generators: [],
    themes: [
      {
        name: 'default',
        author: 'Senz',
      },
      {
        name: 'yellow',
        author: 'Epsilon',
      },
      {
        name: 'black',
        author: '@imnotaredberry',
      },
    ],
  },
  mutations: {
    setGenerators(state, generators) {
      Vue.set(state, 'generators', generators);
    },
  },

  actions: {
    async refreshGenerators(ctx) {
      let generators = [];

      if (process.env.VUE_APP_STATIC_GENERATOR === 'true') {
        generators = [
          {
            id: 'vue-buefy',
            framework: 'Vue',
            design: 'Buefy',
            name: 'Buefy',
            url: `${process.env.VUE_APP_STATIC_GENERATOR_BASE_URL}/vue/buefy`,
          },
          {
            id: 'vue-vuetify',
            framework: 'Vue',
            design: 'Vuetify',
            name: 'Vuetify',
            url: `${process.env.VUE_APP_STATIC_GENERATOR_BASE_URL}/vue/vuetify`,
          },
          {
            id: 'vue-carbon',
            framework: 'Vue',
            design: 'Carbon',
            name: 'Carbon',
            url: `${process.env.VUE_APP_STATIC_GENERATOR_BASE_URL}/vue/carbon`,
          },
          {
            id: 'ng-material',
            framework: 'Angular',
            design: 'Material',
            name: 'Material',
            url: `${process.env.VUE_APP_STATIC_GENERATOR_BASE_URL}/angular/material`,
          },
          {
            id: 'ng-aquila',
            framework: 'Angular',
            design: 'Aquila',
            name: 'Aquila',
            url: `${process.env.VUE_APP_STATIC_GENERATOR_BASE_URL}/angular/aquila`,
          },
          {
            id: 'ng-vgh',
            framework: 'Angular',
            design: 'Aquila',
            name: 'vgh',
            url: `${process.env.VUE_APP_STATIC_GENERATOR_BASE_URL}/angular/vgh`,
          },
          {
            id: 'ng-ndbx',
            framework: 'Angular',
            design: 'Aquila',
            name: 'ndbx',
            url: `${process.env.VUE_APP_STATIC_GENERATOR_BASE_URL}/angular/ndbx`,
          },
        ];
      } else {
        generators = await fire.generator('lab').read();
        const privates = await fire.generators.findByEmail(auth.getEmail());
        generators.push(...privates);
      }
      ctx.commit('setGenerators', generators);
    },
    async predict(ctx, { img }) {
      const uid = auth.getUserId();
      const prediction = await senz.predict({
        uid,
        img,
      });
      return prediction;
    },
  },
};
