import firebase from 'firebase/app';

function signIn(email, password) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

function signInWithGoogle() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'consent',
  });
  provider.addScope('profile');
  provider.addScope('email');
  // provider.addScope('https://www.googleapis.com/auth/drive');
  return firebase.auth().signInWithRedirect(provider);
}

function signInWithMicrosoft() {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');
  provider.setCustomParameters({
    prompt: 'select_account',
  });
  provider.addScope('User.Read');
  return firebase.auth().signInWithRedirect(provider);
}

function signUp(email, password) {
  return firebase.auth().createUserWithEmailAndPassword(email, password);
}

function signOut() {
  return firebase.auth().signOut();
}

function resetPassword(email, url) {
  const data = { email, url };
  return firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('passwordReset')(data);
}

function isLoggedIn() {
  return !!firebase.auth().currentUser;
}

function getUser() {
  return firebase.auth().currentUser;
}

function updatePassword(previousPass, newPass) {
  const user = getUser();

  const credential = firebase.auth.EmailAuthProvider.credential(user.email, previousPass);
  return user.reauthenticateAndRetrieveDataWithCredential(credential).then(res => res.user.updatePassword(newPass));
}

function getUserId() {
  return getUser().uid;
}

function getEmail() {
  return getUser().email;
}

async function getUserToken(forceRefresh = false) {
  if (!isLoggedIn()) return null;
  const token = await getUser().getIdToken(forceRefresh);
  return token;
}

function verifyEmail(email) {
  const data = { email };
  return firebase
    .app()
    .functions('europe-west1')
    .httpsCallable('emailVerification')(data);
}

export default {
  signIn,
  signInWithGoogle,
  signInWithMicrosoft,
  signUp,
  signOut,
  resetPassword,
  updatePassword,
  getUser,
  getUserId,
  getEmail,
  getUserToken,
  isLoggedIn,
  verifyEmail,
};
