import firebase from 'firebase/app';
import { meta, readDoc } from '../utils';

const ref = () => firebase.firestore().collection('users');
const doc = id => ref().doc(id);

export default {
  document: id => {
    if (!id) throw new Error('Invalid document ID');
    return {
      read: () => doc(id)
        .get()
        .then(readDoc),
      write: data => doc(id).set(meta.update(data), { merge: true }),
    };
  },
};
