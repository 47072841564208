export default {
  loading: false,
  projects: {},
  projectUsers: {},
  projectInvites: {},
  prototypes: {},
  pages: {},
  pageFiles: {},
  pageThumbs: {},
  files: {},
  fileDownloads: {},
  presences: [],
};
