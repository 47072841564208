<template lang="pug">
  fullscreen(ref="fullscreen" @change="fullscreenChange")
    v-app(v-if="$store.state.initialized" v-resize.quiet="onResize" :style="style")
      shortcut-overlay
      feedback-modal(v-model="showFeedbackModal")
      //- App bar
      v-app-bar(app :elevate-on-scroll="!$route.meta.elevatedAppBar" v-if="!$route.meta.noAppBar"
        :extension-height="hasAppBarExtension ? ($route.meta.extensionHeight || 48)  : 0"
        color="white" :class="{ hasAppBarExtension }")
        template(v-slot:extension)
          portal-target(name="appbar-extension" slim @change="hasAppBarExtension = $event")

        v-row(no-gutters)
          v-col(:cols="hasAppBarCenter ? 3 : 9").d-flex.align-center
            v-toolbar-title
              portal-target(name="appbar-left" slim)
          v-col(v-if="hasAppBarCenter").d-flex.justify-space-around.align-center
            portal-target(name="appbar-center" slim)
          v-col(cols="3" v-if="!$route.meta.hideAvatarMenu").d-flex.justify-end.align-center
            portal-target(name="appbar-right" slim)
            //- Avatar and menu, or sign in button
            v-menu(left open-on-hover transition="slide-y-transition" )
              template(v-slot:activator="{ on }" )
                v-btn(icon v-on="on").avatar-menu-btn
                  v-avatar(rounded color="primary" size="36")
                    img(v-if="$store.getters['auth/getUser'].photoURL" :src="$store.getters['auth/getUser'].photoURL")
                    span(v-else).white--text.headline {{ $store.getters['auth/getUserInitials'] }}
              v-list(nav).avatar-menu
                v-list-item.headline
                  v-list-item-avatar(rounded color="primary" width="40")
                    img(v-if="$store.getters['auth/getUser'].photoURL" :src="$store.getters['auth/getUser'].photoURL")
                    span(v-else).white--text.headline {{ $store.getters['auth/getUserInitials'] }}
                  v-list-item-content
                    .name {{ $store.getters['auth/getUser'].displayName }}
                    .email {{ $store.getters['auth/getUser'].email }}
                v-divider
                v-list-item(dense href="https://support.senz.io/" target="_blank")
                  v-list-item-icon
                    Icon(src="help-circle")
                  v-list-item-content
                    v-list-item-title Support Page
                v-list-item(dense @click="showFeedbackModal = true")
                  v-list-item-icon
                    Icon(src="message-alert")
                  v-list-item-content
                    v-list-item-title Feedback & Ideas
                v-list-item(dense @click="$events.bus.$emit($events.ids.SHORTCUT_TOGGLE_HELP)")
                  v-list-item-icon
                    Icon(src="keyboard")
                  v-list-item-content
                    v-list-item-title Keyboard Shortcuts
                  v-list-item-action-text Shift+?
                v-list-item(dense @click="logout")
                  v-list-item-icon
                    Icon(src="logout-variant")
                  v-list-item-content
                    v-list-item-title Sign Out
                v-list-item(dense).footer
                  v-btn(text href="https://senz.io/core/data/privacy.html" target="_blank") Privacy Policy
                  span.mx-1 -
                  v-btn(text href="https://myaccount.google.com/permissions" target="_blank") Unlink my Account

      //-  Content routing
      v-main
        v-fade-transition
          router-view

      //-  Update notification
      v-snackbar(v-model="showUpdateAvailable" :timeout="-1" bottom center)
        | new version available
        template(v-slot:action="{ attrs }")
          v-btn(text v-bind="attrs" color="white" @click="installUpdateClicked") update

    //-  Loading state
    v-app(v-else).text-center
      v-skeleton-loader(type="image").senz-app-loader
</template>

<script>
import { Wormhole } from 'portal-vue';
import ShortcutOverlay from '@/views/components/ShortcutOverlay.vue';
import Icon from '@/views/components/Icon.vue';
import FeedbackModal from '@/views/components/modal/Feedback.vue';

export default {
  components: { ShortcutOverlay, Icon, FeedbackModal },
  data: () => ({
    hasAppBarExtension: false,
    height: window.innerHeight,
    width: window.innerWidth,
    showUpdateAvailable: false,
    showFeedbackModal: false,
  }),
  created() {
    window.addEventListener('swUpdate', () => {
      this.showUpdateAvailable = true;
    });
  },
  methods: {
    fullscreenChange(val) {
      this.$store.commit('setFullscreen', val);
    },
    onResize() {
      const { innerWidth, innerHeight } = window;
      if (!innerWidth || !innerHeight) return;
      this.height = innerHeight;
      this.width = innerWidth;
    },
    installUpdateClicked() {
      this.showUpdateAvailable = false;
      if (this.$serviceWorker) {
        this.$serviceWorker.messageSW({ type: 'SKIP_WAITING' });
      }
    },
    async logout() {
      await this.$store.dispatch('auth/signOut');
      window.location.reload(true);
    },
  },
  computed: {
    style() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },
    hasAppBarCenter() {
      return Wormhole.hasContentFor('appbar-center');
    },
  },
  mounted() {
    this.$events.bus.$on(this.$events.ids.SHORTCUT_TOGGLE_FULLSCREEN, () => {
      const { preciseFullscreen, fullscreen } = this.$refs;
      (preciseFullscreen || fullscreen).toggle();
    });
  },
};
</script>

<style lang="scss">
html,
.v-application,
.v-application .v-app-bar,
.v-application .v-app-bar .v-tabs-bar {
  background: #f4f4f4 !important;
}

.senz-app-loader,
.senz-app-loader > .v-skeleton-loader__image {
  width: 100vw;
  height: 100vh;
}
.v-app-bar {
  transition: height 0.5s ease !important;
  .v-toolbar__extension {
    opacity: 0;
    padding: 0;
    transition: 0.5s height ease, 1s opacity ease;
  }
  .v-toolbar__title {
    overflow: visible;
  }
  .avatar-menu-btn {
    margin-left: 2px;
  }
  .actions {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
}
.v-app-bar.hasAppBarExtension {
  .v-toolbar__extension {
    opacity: 1;
    padding: 2px 10px;
  }
}

.v-application--wrap {
  min-height: 100% !important;
}
.appbar-logo {
  width: 80px;
  max-height: 40px;
  vertical-align: middle;
}
.avatar-menu {
  padding: 4px 8px !important;
  .v-list-item {
    margin: 0 !important;
  }
  .v-list-item__icon {
    margin-right: 16px !important;
  }
  .v-list-item__title {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    color: #5f6368;
  }
  .v-list-item__action-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    height: 16px;
    margin-top: 1px;
    color: #7f7f7f !important;
  }
  .headline {
    .v-avatar span {
      width: 100%;
      text-align: center;
    }
    .name {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #5f6368;
      margin-bottom: 3px;
    }
    .email {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #7f7f7f;
    }
  }
  .footer {
    height: 22px !important;
    min-height: auto;
    text-align: center;
    align-items: center;
    justify-content: center;

    .v-btn,
    span {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 18px;
      height: 18px !important;
      padding: 0 !important;
      text-align: center;
      color: #5f6368;
      text-transform: none;
    }
  }
}
</style>
