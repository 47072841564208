import firebase from 'firebase/app';
import auth from '@/api/fire/auth';
import db from '@/api/fire/db';
import fn from '@/api/fire/functions';
import events from '@/plugins/events';

const cachedProfiles = Object.keys(sessionStorage)
  .filter(key => !!key.startsWith('profile'))
  .map(key => JSON.parse(sessionStorage.getItem(key)))
  .reduce((profiles, p) => ({
    [p.uid]: p,
    ...profiles,
  }), {});

const state = {
  signedIn: false,
  profiles: cachedProfiles,
  data: {},
  userId: null,
};

const mutations = {
  userSignedIn(ctx) {
    ctx.signedIn = true;
    ctx.userId = auth.getUserId();
    events.bus.$emit(events.ids.USER_STATE_CHANGE);
    firebase.analytics().setUserId(auth.getUserId());
  },
  userSignedOut(ctx) {
    ctx.signedIn = false;
    ctx.userId = null;
    events.bus.$emit(events.ids.USER_STATE_CHANGE);
    firebase.analytics().setUserId(null);
  },
  setProfiles(ctx, { emailProfiles, uidProfiles }) {
    [...emailProfiles, ...uidProfiles].forEach(profile => {
      if (profile.uid) {
        ctx.profiles[profile.uid] = profile;
        sessionStorage.setItem(`profile.${profile.uid}`, JSON.stringify(profile));
      }
    });
  },
  setUserData(ctx, { data }) {
    ctx.data = data;
  },
};

const actions = {
  async signIn(ctx, { email, password }) {
    return auth.signIn(email, password);
  },
  async signInWithGoogle() {
    return auth.signInWithGoogle();
  },
  async signInWithMS() {
    return auth.signInWithMicrosoft();
  },
  onSocialSignIn(ctx, res) {
    if (res.additionalUserInfo.isNewUser) {
      console.info('New user signed in from social account');
    }
  },
  async signUp(ctx, { email, password, name }) {
    const credential = await auth.signUp(email, password);
    if (name) await credential.user.updateProfile({ displayName: name });
    return credential;
  },
  async signOut() {
    return auth.signOut();
  },
  async resetPassword(ctx, { email, url }) {
    return auth.resetPassword(email, url);
  },
  async updatePassword(ctx, { previousPass, newPass }) {
    return auth.updatePassword(previousPass, newPass);
  },
  async refreshUserData(ctx) {
    const data = await db.user(auth.getUserId()).read();
    ctx.commit('setUserData', { data });
  },
  async updateUserData(ctx, data) {
    await db.user(auth.getUserId()).write(data);
    return ctx.dispatch('refreshUserData');
  },
  async fetchProfiles(ctx, { emails, uids }) {
    const emailProfiles = [];
    const uidProfiles = [];
    const missingEmails = [];
    const missingUids = [];
    const profiles = Object.values(ctx.state.profiles);
    (emails || []).forEach(email => {
      if (!profiles.find(p => p.email === email)) missingEmails.push(email);
    });
    (uids || []).forEach(uid => {
      if (!ctx.state.profiles[uid]) missingUids.push(uid);
    });

    if (missingEmails.length || missingUids.length) {
      const response = await fn.fetchProfiles({ emails: missingEmails, uids: missingUids });
      emailProfiles.push(...response.data.emailProfiles);
      uidProfiles.push(...response.data.uidProfiles);
    }
    if (emailProfiles.length || uidProfiles.length) {
      ctx.commit('setProfiles', { emailProfiles, uidProfiles });
    }
  },
};

const getters = {
  getUserInitials() {
    if (!auth.isLoggedIn()) return '';
    const { email } = auth.getUser();
    return `${email.charAt(0)}`.toUpperCase();
  },
  getUser() {
    return auth.getUser() || {};
  },
  getProfile: ctx => uid => ctx.profiles[uid] || {},
  getProfileByEmail: ctx => email => Object.values(ctx.profiles).find(p => p.email === email) || {},
  getUserData: ctx => ctx.data || {},
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
