import projectActions from './project';
import versionActions from './version';
import pageActions from './page';
import userActions from './user';
import protoActions from './prototype';

export default {
  ...projectActions,
  ...versionActions,
  ...pageActions,
  ...userActions,
  ...protoActions,
};
