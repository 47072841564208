import Vue from 'vue';
import { version } from '@senzio/host';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/serviceWorker';
import './plugins/senz';
import './plugins/firebase';
import './plugins/webfont';
import './plugins/icons';
import './plugins/dayjs';
import './plugins/fullscreen';
import './plugins/events';
import './plugins/shortcuts';
import './plugins/portal';
import sentry from './plugins/sentry';
import './filters';

if (process.env.VUE_APP_MODE === 'beta' || process.env.VUE_APP_MODE === 'lab') {
  sentry(router);
} else {
  Vue.config.errorHandler = e => {
    console.error(e);
  };
}

console.groupCollapsed('Senz 🚀');
console.log('Context', process.env.VUE_APP_MODE, process.env.VUE_APP_FIRE_PROJECT_ID);
console.log('Version', process.env.VUE_APP_VERSION);
console.log('Host', version);
console.log('Build', process.env.VUE_APP_GIT_INFO);
console.log('Mode', process.env.NODE_ENV);
console.groupEnd();

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
