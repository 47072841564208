import Vue from 'vue';
import events from '@/plugins/events';

const keyHolds = Vue.observable({
  shift: false,
  ctrl: false,
  space: false,
  arrowDown: false,
  arrowUp: false,
  arrowLeft: false,
  arrowRight: false,
});

document.addEventListener('keydown', event => {
  keyHolds.shift = event.shiftKey;
  keyHolds.ctrl = event.ctrlKey;
  if (event.target.nodeName && event.target.nodeName.toUpperCase() === 'INPUT') return;
  if (event.target.nodeName && event.target.nodeName.toUpperCase() === 'TEXTAREA') return;

  if (event.code === 'Space') {
    keyHolds.space = true;
    return;
  }

  if (event.key === 'Z' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
    events.bus.$emit(events.ids.SHORTCUT_REDO);
    return;
  }

  if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
    events.bus.$emit(events.ids.SHORTCUT_UNDO);
    return;
  }

  if (event.key === 'Backspace' && (event.ctrlKey || event.metaKey) && event.altKey) {
    events.bus.$emit(events.ids.SHORTCUT_CLEAR);
    return;
  }

  if (event.key === 'Backspace' || event.key === 'Delete') {
    events.bus.$emit(events.ids.SHORTCUT_ERASE);
    return;
  }

  if (event.key === 'd' && (event.ctrlKey || event.metaKey)) {
    events.bus.$emit(events.ids.SHORTCUT_CLONE);
    return;
  }

  if (event.key === 'D' && event.shiftKey) {
    events.bus.$emit(events.ids.SHORTCUT_TOGGLE_LABELS);
  }

  if (event.key === 'Escape') {
    events.bus.$emit(events.ids.SHORTCUT_ESCAPE);
    return;
  }

  if ((event.key === '?' && event.shiftKey) || (event.key === '/' && event.metaKey)) {
    events.bus.$emit(events.ids.SHORTCUT_TOGGLE_HELP);
    return;
  }

  if (event.key === 'e' && (event.ctrlKey || event.metaKey)) {
    events.bus.$emit(events.ids.SHORTCUT_EXPORT);
    return;
  }

  if (event.key === 'i' && (event.ctrlKey || event.metaKey)) {
    events.bus.$emit(events.ids.SHORTCUT_UPLOAD);
    return;
  }

  if (event.key === '0') {
    events.bus.$emit(events.ids.SHORTCUT_RECOGNISE);
    return;
  }

  if (event.key === 't') {
    events.bus.$emit(events.ids.SHORTCUT_TOGGLE_THEME);
    return;
  }

  if (event.key === '1' || event.key === 'p') {
    events.bus.$emit(events.ids.SHORTCUT_MODE_DRAW);
    return;
  }

  if (event.key === '2' || event.key === 'v') {
    events.bus.$emit(events.ids.SHORTCUT_MODE_SELECT);
    return;
  }

  if (event.key === '3' || event.key === 'e') {
    events.bus.$emit(events.ids.SHORTCUT_MODE_ERASE);
    return;
  }

  if (event.key === '4' || event.key === 'm') {
    events.bus.$emit(events.ids.SHORTCUT_MODE_MOVE);
    return;
  }

  if (event.key === '5' || event.key === 'g') {
    events.bus.$emit(events.ids.SHORTCUT_TOGGLE_BACKGROUND);
    return;
  }

  if (event.key === 'f') {
    events.bus.$emit(events.ids.SHORTCUT_TOGGLE_FULLSCREEN);
    return;
  }

  if (event.key === 'C' && event.shiftKey) {
    events.bus.$emit(events.ids.SHORTCUT_TOGGLE_CANVAS);
    return;
  }

  if (event.key === 'S' && event.shiftKey) {
    events.bus.$emit(events.ids.SHORTCUT_SPLIT_VIEW);
    return;
  }

  if (event.key === 'Q' && event.shiftKey) {
    events.bus.$emit(events.ids.SHORTCUT_QR_CODE);
    return;
  }

  if (event.key === 'a' && (event.ctrlKey || event.metaKey)) {
    events.bus.$emit(events.ids.SHORTCUT_SELECT_ALL);
    event.preventDefault();
  }

  if (event.key === 'ArrowDown') {
    events.bus.$emit(events.ids.SHORTCUT_ARROW_MOVE, { direction: 'down' });
    keyHolds.arrowDown = true;
  }
  if (event.key === 'ArrowUp') {
    events.bus.$emit(events.ids.SHORTCUT_ARROW_MOVE, { direction: 'up' });
    keyHolds.arrowUp = true;
  }
  if (event.key === 'ArrowLeft') {
    events.bus.$emit(events.ids.SHORTCUT_ARROW_MOVE, { direction: 'left' });
    keyHolds.arrowLeft = true;
  }
  if (event.key === 'ArrowRight') {
    events.bus.$emit(events.ids.SHORTCUT_ARROW_MOVE, { direction: 'right' });
    keyHolds.arrowRight = true;
  }
});

document.addEventListener('keyup', event => {
  keyHolds.shift = event.shiftKey;
  keyHolds.ctrl = event.ctrlKey;
  if (event.code === 'Space') {
    keyHolds.space = false;
  }
});

document.addEventListener('copy', event => {
  if (event.target.nodeName && event.target.nodeName.toUpperCase() === 'INPUT') return;
  if (event.target.nodeName && event.target.nodeName.toUpperCase() === 'TEXTAREA') return;
  events.bus.$emit(events.ids.SHORTCUT_COPY, { event });
});
document.addEventListener('paste', event => {
  if (event.target.nodeName && event.target.nodeName.toUpperCase() === 'INPUT') return;
  if (event.target.nodeName && event.target.nodeName.toUpperCase() === 'TEXTAREA') return;
  events.bus.$emit(events.ids.SHORTCUT_PASTE, { event });
});

Vue.prototype.$keyHolds = keyHolds;

Vue.mixin({
  computed: {
    hasArrowKeyPressed() {
      return keyHolds.arrowDown || keyHolds.arrowUp || keyHolds.arrowLeft || keyHolds.arrowRight;
    },
  },
});
