<template lang="pug">
  v-dialog(v-bind="$attrs" v-on="$listeners" max-width="420")
    v-card(v-if="!showSubmitMessage")
      v-card-title Feedback & Ideas
      v-card-text
        v-form(ref="form")
          p.body-1 We would love to hear your thoughts, concerns or problems with anything so we can improve!
          v-select(filled dense required id="1905909196" v-model="type"
            :items="types" label="Option" :rules="[v => !!v || 'Choose a option']")
          v-textarea(outlined hide-details no-resize v-model="message"
            label="Tell us more about your feedback" :rules="[v => !!v || 'Enter a message']")
          .d-flex.align-center
            v-checkbox(dense v-model="makePublic"
              label="Yes, please feel free to make my feedback public!").mr-8
            v-btn(color="primary" :loading="loading" @click="submit") Submit
      v-divider.mx-4.mb-4
      v-card-text
        p.body-2 If appealing your comment can become part of our knowledge base, Q&A and even become part of our documentation.
    v-card(v-else)
      v-card-title
      v-card-text
        span.body-1 Thank you for sharing your thoughts!
</template>

<script>
export default {
  data() {
    return {
      message: '',
      makePublic: false,
      type: null,
      types: ['Product Question', 'Bug Report', 'Feature request', 'Other'],
      loading: false,
      showSubmitMessage: false,
    };
  },
  watch: {
    '$attrs.value': {
      handler(value) {
        if (value) {
          this.showSubmitMessage = false;
        }
      },
    },
  },
  methods: {
    async submit() {
      const valid = this.$refs.form.validate();
      if (!valid) return;

      this.loading = true;
      const formData = new FormData();
      formData.append('entry.1354872553', this.type);
      formData.append('entry.1607958302', this.message);
      formData.append('entry.1528996861', this.$store.getters['auth/getUser'].email);
      if (this.makePublic) formData.append('entry.199336074', 'Yes');

      await fetch(
        'https://docs.google.com/forms/d/e/1FAIpQLSe1EfK5lwjNSGDLB_h-Jd5hy_NS18tfL7d5UPGyPjUM9KLczw/formResponse',
        {
          method: 'POST',
          mode: 'no-cors',
          body: formData,
        },
      );
      this.loading = false;
      this.$refs.form.reset();
      this.showSubmitMessage = true;
    },
  },
};
</script>
