import firebase from 'firebase/app';
import axios from 'axios';

const getRootFolder = root => () => {
  let subfolder;
  const folderRef = () => firebase.storage().ref(`${root}/${subfolder || ''}`);

  return {
    file: name => {
      const fileRef = folderRef().child(name);
      return {
        upload: (fileDoc, metadata) => fileRef.put(fileDoc, metadata),
        getDownloadUrl: () => fileRef.getDownloadURL(),
        getMetadata: () => fileRef.getMetadata(),
        updateMetadata: meta => fileRef.updateMetadata(meta),
        delete: () => fileRef.delete(),
        downloadAsText: async () => {
          try {
            const url = await fileRef.getDownloadURL();
            const resp = await axios({ url, method: 'GET', responseType: 'blob' });
            if (resp.status !== 200) return null;
            return new Promise(resolve => {
              const reader = new FileReader();
              reader.addEventListener('loadend', () => resolve(reader.result));
              reader.addEventListener('error', () => resolve(null));
              reader.readAsText(resp.data);
            });
          } catch (e) {
            return null;
          }
        },
        downloadAsBlob: async () => {
          try {
            const url = await fileRef.getDownloadURL();
            const resp = await axios({ url, method: 'GET', responseType: 'blob' });
            if (resp.status !== 200) return null;
            return resp.data;
          } catch (e) {
            return null;
          }
        },
      };
    },
    list: options => folderRef().list(options),
    listAll: () => folderRef().listAll(),
    dir(name) {
      subfolder = subfolder ? `${subfolder}/${name}` : name;
      return this;
    },
  };
};

export default {
  pages: getRootFolder('pages'),
  prototypes: getRootFolder('prototypes'),
  projects: getRootFolder('projects'),
};
