import Vue from 'vue';
import Vuex from 'vuex';
import events from '../plugins/events';
import projects from './projects/index';
import senz from './senz/index';
import auth from './auth/index';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    projects,
    senz,
    auth,
  },
  state: {
    initialized: false,
    onboarded: JSON.parse(localStorage.getItem('onboarded') || false),
    isFullscreen: false,
  },
  mutations: {
    hasBeenOnboarded(ctx) {
      ctx.onboarded = true;
      localStorage.setItem('onboarded', true);
    },
    appInitialized(ctx) {
      ctx.initialized = true;
      events.bus.$emit(events.ids.APP_INITIALIZED);
    },
    setFullscreen(ctx, isFullscreen) {
      ctx.isFullscreen = isFullscreen;
    },
  },
  actions: {},
});
