<template lang="pug">
  v-list-item-avatar(v-bind="$attrs" v-on="$listeners" v-if="listItem" rounded color="primary").snz-avatar
    v-img(v-if="user.profile.photoURL" :src="user.profile.photoURL")
    span(v-else).snz-txt-avatar.white--text
      | {{ (user.profile.displayName || user.email).charAt(0) }}
  v-avatar(v-bind="$attrs" v-on="$listeners" v-else rounded color="primary").snz-avatar
    v-img(v-if="user.profile.photoURL" :src="user.profile.photoURL")
    span(v-else).snz-txt-avatar.white--text
      | {{ (user.profile.displayName || user.email).charAt(0) }}
</template>

<script>
export default {
  name: 'Avatar',
  inheritAttrs: false,
  props: {
    user: {
      type: Object,
      required: true,
    },
    listItem: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.snz-avatar {
  border-radius: 4px;
}
</style>
