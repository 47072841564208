import firebase from 'firebase/app';
import { readDoc } from '../utils';

const ref = () => firebase.firestore().collection('generators');
const doc = id => ref().doc(id);

export default {
  collection: {
    findByEmail: async email => {
      const instances = [];
      const results = await ref()
        .where('users', 'array-contains', email)
        .get();
      results.forEach(res => instances.push(...readDoc(res).instances));

      const [, domain] = email.split('@');
      const domainResults = await ref()
        .where('domains', 'array-contains', domain)
        .get();
      domainResults.forEach(res => instances.push(...readDoc(res).instances));
      return instances;
    },
  },
  document: id => {
    if (!id) throw new Error('Invalid document ID');
    return {
      read: () => doc(id)
        .get()
        .then(readDoc)
        .then(data => data.instances),
    };
  },
};
