<template lang="pug">
#page-login
  img(src="~@res/images/senz.svg").logo
  .card-container
    .top-buttons
      c-button(variant="text" v-if="mode === 'signup'"
      :disabled="performingRequest" @click="mode = 'login'" classes="transparent") Already have a Senz account?
      c-button(variant="text" v-if="mode === 'forgotpassword'"
      :disabled="performingRequest" @click="mode = 'login'" classes="transparent") Back to my account?
      c-button(variant="outlined" v-if="mode !== 'login'"
      :disabled="performingRequest" @click="mode = 'login'" classes="transparent") Login
      c-button(variant="text" v-if="mode === 'login'"
      :disabled="performingRequest" @click="mode = 'signup'" classes="transparent") Don't have a Senz account?
      c-button(variant="outlined" v-if="mode === 'login'"
      :disabled="performingRequest" @click="mode = 'signup'" classes="transparent") Sign Up

    .snz-card
      .snz-txt-h4(v-if="headTitle") {{ headTitle}}
      .snz-txt-body-1(v-if="headDescription") {{ headDescription}}
      .snz-txt-h5(v-if="subtitle") {{ subtitle}}

      .oauth-buttons(v-if="mode === 'login' || mode === 'signup'")
        c-button(variant="outlined" :disabled="performingRequest" @click="signInWithGoogle")
          | Using Google
          template(v-slot:prepend)
            img(:src="icons.google")
        c-button(variant="outlined" :disabled="performingRequest" @click="signInWithMS" )
          | Using Microsoft
          template(v-slot:prepend)
            img(:src="icons.microsoft")

      div(v-if="mode === 'login' || mode === 'signup'")
        c-divider(label="Or")

      form(@submit.prevent="onSubmit")
        c-input(placeholder='Display name' v-model="name" v-if="mode === 'signup'" key="login-name-input")
        c-input(placeholder='Email' v-model="email" type="email" v-if="mode !== 'reset'" :key="mode + '-email-input'")
        c-input(placeholder='Password' type='password' :isPassword='true' v-model="password"
        v-if="mode !== 'forgotpassword'" :key="mode + 'password-input'")
        c-input(placeholder='Confirm password' type='password' :isPassword='true' v-model="confirmPassword" v-if="mode === 'signup'")

        .action-buttons
          c-button(variant="text" v-show="mode === 'login'" :disabled="performingRequest"
          @click.prevent="mode = 'forgotpassword'") Forgot password ?
          c-button(variant="primary" v-show="mode === 'login'" :loading="performingRequest"
          type="submit" key="login-submit") Login

          c-button(variant="text" v-show="mode === 'forgotpassword' || mode === 'reset'"
          :disabled="performingRequest" @click.prevent="mode = 'login'") Back To Login
          c-button(variant="primary" v-show="mode === 'forgotpassword' || mode === 'reset'"
          :loading="performingRequest" type="submit" key="reset-submit") Reset

          .sign-up-actions(v-if="mode === 'signup'" key="sign-up-buttons")
            c-checkbox(v-model="agreedToS" id="agree-tos-button" labelClass="snz-txt-caption").mr2
              | Creating an account with Senz means you’re okay with our!{' '}
              a(href="https://senz.io/core/data/terms.html" target="_blank") Terms of service
              |  and!{' '}
              a(href="https://senz.io/core/data/privacy.html" target="_blank") our Privacy policy
              | .
            c-button(variant="primary" :disabled="agreedToS === false" :loading="performingRequest" type="submit"
              key="signup-submit") Sign Up
      c-divider

      .snz-txt-caption When sign in, we will collect some data to personalise your experience and help us improve our services.
        |  For more info refer to our!{' '}
        a(href="https://senz.io/core/data/terms.html" target="_blank") Terms of service
        | .

  v-snackbar(v-model="showSuccessSnackbar" bottom right color="success" :timeout="5000")
    v-icon.mr-4 fa-check
    | {{ successMsg }}
  v-snackbar(v-model="showErrorSnackbar" bottom right color="error" :timeout="5000")
    v-icon.mr-4 fa-exclamation-circle
    | {{ errorMsg }}
</template>

<script>
import microsoft from '@senzio/crayon/lib/icons/microsoft.svg';
import google from '@senzio/crayon/lib/icons/google.svg';

export default {
  data() {
    return {
      mode: 'login',
      email: '',
      name: '',
      password: '',
      confirmPassword: '',
      passwordError: '',
      showSuccessSnackbar: false,
      showErrorSnackbar: false,
      passwordResetSuccess: false,
      performingRequest: false,
      agreedToS: false,
      successMsg: '',
      errorMsg: '',
    };
  },
  watch: {
    password() {
      this.passwordError = '';
    },
    confirmPassword() {
      this.passwordError = '';
    },
  },
  computed: {
    icons() {
      return {
        microsoft,
        google,
      };
    },
    headTitle() {
      switch (this.mode) {
        case 'signup':
          return 'Create your account';
        case 'forgotpassword':
          return 'Forgot your password';
        default:
          return 'Log in your account';
      }
    },
    headDescription() {
      switch (this.mode) {
        case 'signup':
          return 'Ready to turn ideas into actions with your team, use your profesionnal account to smoothen collaboration.';
        case 'forgotpassword':
          return 'No worries, just enter your email and we’ll send you a reset link.';
        default:
          return 'Welcome back! Log into Senz to get access to your dashboard and projects.';
      }
    },
    subtitle() {
      switch (this.mode) {
        case 'signup':
          return 'Sign up';
        case 'forgotpassword':
          return 'Email account';
        default:
          return 'Log in';
      }
    },
  },
  methods: {
    onSubmit() {
      if (this.mode === 'signup') {
        this.signup();
      } else if (this.mode === 'login') {
        this.login();
      } else if (this.mode === 'forgotpassword') {
        this.resetPassword();
      }
    },
    login() {
      this.performingRequest = true;
      const { email, password } = this;
      this.$store
        .dispatch('auth/signIn', { email, password })
        .then(() => {
          this.performingRequest = false;
        })
        .catch(err => {
          this.performingRequest = false;
          this.errorMsg = err.message;
          this.showErrorSnackbar = true;
        });
    },
    signup() {
      const {
        email, password, confirmPassword, name,
      } = this;

      if (password !== confirmPassword) {
        this.passwordError = 'Passwords are not identical';
        return;
      }
      if (!name) return;

      this.performingRequest = true;
      this.$store
        .dispatch('auth/signUp', {
          email,
          password,
          name,
        })
        .then(() => {
          this.performingRequest = false;
          this.showSuccessSnackbar = true;
          this.successMsg = "You signed up successfully! We've sent you an email to verify your mail address";
        })
        .catch(err => {
          this.performingRequest = false;
          this.errorMsg = err.message;
          this.showErrorSnackbar = true;
        });
    },
    resetPassword() {
      const { email } = this;
      this.performingRequest = true;
      this.$store
        .dispatch('auth/resetPassword', { email, url: window.location.origin })
        .then(() => {
          this.performingRequest = false;
          this.passwordResetSuccess = true;
          this.showSuccessSnackbar = true;
          this.successMsg = "We've sent you an email to let you reset your password";
        })
        .catch(err => {
          console.log(err);
          this.performingRequest = false;
          this.errorMsg = err.message;
          this.showErrorSnackbar = true;
        });
    },
    async signInWithGoogle() {
      this.performingRequest = true;
      await this.$store.dispatch('auth/signInWithGoogle');
      this.performingRequest = false;
    },
    async signInWithMS() {
      this.performingRequest = true;
      await this.$store.dispatch('auth/signInWithMS');
      this.performingRequest = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#page-login {
  display: flex;
  height: 100%;
  padding: 48px 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .logo {
    position: fixed;
    top: 22px;
    left: 38px;
    height: 24px;
    @media (max-width: 640px) {
      height: 16px;
      top: 16px;
      left: 16px;
    }
  }
  .card-container {
    max-width: 370px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    @media (max-width: 640px) {
      max-width: 96vw;
    }
  }
  .top-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  .snz-card {
    .snz-txt-body-1 {
      margin: 20px 0;
      color: rgba(0, 0, 0, 0.6);
    }

    .snz-txt-caption {
      color: rgba(0, 0, 0, 0.6);

      a {
        color: inherit;
      }
    }

    .snz-txt-h5 {
      margin-bottom: 18px;
    }

    .c-divider {
      margin: 15px 0;
    }

    .c-input {
      margin: 15px 0;
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;

      .sign-up-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 0px;

        span {
          color: rgba(0, 0, 0, 0.6) !important;
          a {
            color: inherit;
          }
        }
      }
    }

    .c-divider-line {
      margin-bottom: 18px;
    }

    .snz-txt-h4 {
      color: rgba(0, 0, 0, 0.8);
    }

    .snz-txt-caption {
      text-align: justify;
      text-justify: inter-word;
      display: block;
    }
  }
  .oauth-buttons {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .c-button {
      padding: 6px 10px;
    }
  }
}
</style>
