import Vue from 'vue';
import { Workbox } from 'workbox-window';

let wb = null;
const { hostname } = window.location;
if (process.env.NODE_ENV === 'production' && (hostname === 'make.senz.io')) {
  if ('serviceWorker' in navigator) {
    wb = new Workbox(`${process.env.BASE_URL}service-worker.js`);
    wb.addEventListener('waiting', () => {
      window.dispatchEvent(
        new CustomEvent('swUpdate'),
      );
    });
    wb.addEventListener('externalwaiting', () => {
      window.dispatchEvent(
        new CustomEvent('swUpdate'),
      );
    });
    wb.addEventListener('controlling', () => {
      window.location.reload();
    });
    wb.register();
  }
}

Vue.prototype.$serviceWorker = wb;
