import db from '@/api/fire/db';
import auth from '@/api/fire/auth';

const presence = {
  projectId: null,
  sub: null,
};

export default {
  /**
   * Refresh all projects the user have access to
   */
  async refreshProjects(ctx) {
    const projects = await db.projects.findUserProjects(auth.getUserId(), auth.getEmail());
    ctx.commit('setProjects', { projects });
  },

  async refreshProject(ctx, { projectId }) {
    const project = await db.project(projectId).read(auth.getUserId(), auth.getEmail());
    ctx.commit('setProject', { projectId, project });
  },

  async saveProjectName(ctx, { projectId, name }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).write({
      name,
    });
    return ctx.dispatch('refreshProject', { projectId });
  },

  async saveProjectDescription(ctx, { projectId, description, tags }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).write({
      tags,
      description,
    });
    return ctx.dispatch('refreshProject', { projectId });
  },

  async saveProjectResources(ctx, { projectId, resources }) {
    await db.project(projectId).write({ resources });
    return ctx.dispatch('refreshProject', { projectId });
  },

  async createProject(ctx, { name, empty }) {
    const uid = auth.getUserId();
    await ctx.dispatch('senz/refreshGenerators', null, { root: true });
    const project = await db.projects.create({
      name,
      owners: [uid],
      metas: {
        created_by: uid,
      },
      versions: {},
    });
    if (!empty) {
      await db.project(project.id).versions.create('default', {
        name: 'Default',
        config: {
          framework: ctx.rootState.senz.generators[0].id,
          theme: ctx.rootState.senz.themes[0].name,
        },
        pages: [],
        metas: {
          created_by: uid,
        },
      });
    }
    return project.id;
  },

  async deleteProject(ctx, { projectId }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).delete();
    return ctx.dispatch('refreshProjects');
  },

  async subscribeToPresence(ctx, { projectId }) {
    if (presence.projectId === projectId) return;
    if (presence.sub) presence.sub();
    presence.projectId = projectId;
    presence.sub = db.project(projectId).presences.subscribe(presences => {
      ctx.commit('setPresences', { presences });
    });
  },

  async unsubscribePresence() {
    if (presence.sub) presence.sub();
    presence.sub = null;
  },
};
