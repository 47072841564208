<template lang="pug">
  .snz-stat-label(:class="{ dark }")
    .snz-stat-label_value
      slot _ _
    .snz-stat-label_label.snz-txt-caption {{ label }}
</template>

<script>
export default {
  name: 'StatLabel',
  props: {
    label: {
      type: String,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.snz-stat-label {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  &_value {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: var(--snz-font-high);
  }
  &_label {
    margin-top: 4px;
    color: var(--snz-font-medium);
    white-space: pre;
  }
  &.dark {
    .snz-stat-label_value {
      color: #fff;
    }
    .snz-stat-label_label {
      color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
