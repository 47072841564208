import db from '@/api/fire/db';
import storage from '@/api/fire/storage';
import { version as hostVersion } from '@senzio/host';

const genId = () => `v${Math.random().toString(36).substr(2, 9)}`;

export default {

  async refreshPrototypes(ctx, { projectId }) {
    const prototypes = await db.project(projectId).prototypes.getAll();
    ctx.commit('setPrototypes', { prototypes });
  },
  async refreshPrototype(ctx, { projectId, versionId, protoId }) {
    let prototypeId = protoId;
    if (versionId) {
      prototypeId = ctx.getters.getVersion(projectId, versionId).protoId;
    }
    const prototype = await db.project(projectId).prototype(prototypeId).read();
    ctx.commit('setPrototypes', { prototypes: [prototype] });
  },

  async syncPrototype(ctx, {
    projectId, versionId, analytics, password, live,
  }) {
    if (!ctx.getters.hasPermission(projectId, 'publish_prototypes')) {
      throw new Error('Unauthorized action');
    }
    await ctx.dispatch('refreshProject', { projectId });
    const project = ctx.getters.getProject(projectId);
    const projectVersion = ctx.getters.getVersion(projectId, versionId);
    let { protoId } = projectVersion;
    if (protoId) {
      await db.project(projectId).prototype(protoId).write({
        analytics,
        password,
        live,
      });
    } else {
      protoId = genId();
      await db.project(projectId).prototypes.create(protoId, {
        id: protoId,
        versionId,
        analytics,
        password,
        live,
        pageCount: projectVersion.pages.length,
      });
      await db.project(projectId).version(versionId).write({
        protoId,
        live,
      });
      await ctx.dispatch('refreshProject', { projectId });
    }

    await ctx.dispatch('refreshPrototype', { projectId, protoId });
    if (!live) return protoId;

    await ctx.dispatch('refreshPages', { projectId, versionId });
    await ctx.dispatch('senz/refreshGenerators', null, { root: true });
    const generator = ctx.rootState.senz.generators.find(m => m.id === projectVersion.config.framework);
    const pageDatas = ctx.getters.getPages(projectId, versionId);
    const pages = await Promise.all(
      pageDatas.map(async page => {
        const prediction = await ctx.dispatch('downloadPrediction', { pageId: page.id });
        return {
          id: page.id,
          name: page.name,
          components: Object.values(page.components || {}),
          actions: Object.values(page.actions || {}),
          content: prediction,
        };
      }),
    );

    const protoData = {
      project: {
        id: projectId,
        name: project.name,
        version: versionId,
        versionName: projectVersion.name,
      },
      host: hostVersion,
      generator: generator.url,
      config: {
        ...projectVersion.config,
        analytics,
      },
      vars: projectVersion.vars,
      pages,
    };

    const protoJson = new Blob([JSON.stringify(protoData)], { type: 'application/json' });
    await storage.prototypes().file(`${protoId}.json`).upload(protoJson);
    return protoId;
  },
};
