export default {
  getProjects: ctx => Object.values(ctx.projects)
    .sort((p1, p2) => p1.metas.updated_at.toMillis() - p2.metas.updated_at.toMillis())
    .reverse(),
  getProject: ctx => projectId => ctx.projects[projectId],
  getVersion: ctx => (projectId, versionId) => ({
    id: versionId,
    ...ctx.projects[projectId]?.versions[versionId],
  }),
  getVersionVars: ctx => (projectId, versionId) => {
    const vars = ctx.projects[projectId]?.versions[versionId]?.vars;
    if (!vars) return [];
    return Object.values(vars);
  },
  getPages: ctx => (projectId, versionId) => {
    const project = ctx.projects[projectId];
    if (!project) return [];
    const version = project.versions[versionId];
    const pages = version?.pages || [];
    return pages
      ?.map(pageId => ctx.pages[pageId])
      ?.filter(page => !!page)
      ?.sort((p1, p2) => p1.metas.created_at.toMillis() - p2.metas.created_at.toMillis());
  },
  getUsers: ctx => projectId => ctx.projectUsers[projectId] || [],
  getPrototypes: ctx => projectId => {
    const project = ctx.projects[projectId];
    if (!project) return [];
    return Object.values(project.versions)
      .map(version => version.protoId)
      .filter(id => !!id)
      .map(id => ctx.prototypes[id]);
  },
  getPrototype: ctx => protoId => ctx.prototypes[protoId],
  getPage: ctx => pageId => ctx.pages[pageId],
  getPageThumb: ctx => pageId => ctx.pageThumbs[pageId],
  getFiles: ctx => pageId => ctx.pageFiles[pageId].map(fileId => ctx.files[fileId]),
  getFileByName: ctx => (pageId, name) => {
    const files = ctx.pageFiles[pageId].map(fileId => ctx.files[fileId]);
    return files.find(f => f.name === name);
  },
  getImage: ctx => (fileId, mimeType) => {
    const binary = ctx.fileDownloads[fileId];
    const contentArray = new Array(binary.length);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < contentArray.length; i++) {
      contentArray[i] = binary.charCodeAt(i);
    }
    const byteArray = new Uint8Array(contentArray);
    const blob = new Blob([byteArray], { type: mimeType });
    return blob;
  },
  getJSON: ctx => fileId => {
    const data = ctx.drive.fileDownloads[fileId];
    return JSON.parse(data);
  },
  getPresencesByVersion: ctx => versionId => ctx.presences.filter(p => p.versionId === versionId),
  getPresencesByPage: ctx => pageId => ctx.presences.filter(p => p.pageId === pageId),
  hasPermission: ctx => (projectId, permission) => {
    if (projectId === 'drawer') return true;
    const project = ctx.projects[projectId];
    return !!(project?.isOwner || project?.permissions.includes(permission));
  },
};
