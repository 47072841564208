import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/analytics';

import store from '@/store';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIRE_API_KEY,
  authDomain: process.env.VUE_APP_FIRE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIRE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIRE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIRE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIRE_APP_ID,
  measurementId: process.env.VUE_APP_FIRE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);
// firebase.setLogLevel('debug');

if (process.env.VUE_APP_MODE === 'emulator' || window.location.host === 'localhost:4000') {
  firebase.firestore().useEmulator('localhost', 5001);
  firebase.app().functions('europe-west1').useEmulator('localhost', 5002);
  firebase.auth().useEmulator('http://localhost:5003');
  firebase.storage().useEmulator('localhost', 5004);
  firebase.database().useEmulator('localhost', 5005);
} else if (process.env.NODE_ENV === 'production' && window.location.hostname !== 'localhost') {
  firebase.analytics();
}

firebase
  .auth()
  .getRedirectResult()
  .then(result => {
    if (result && result.credential && result.user) {
      store.dispatch('auth/onSocialSignIn', result);
    }
  });

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    store.commit('auth/userSignedIn');
  } else {
    store.commit('auth/userSignedOut');
  }
  if (!store.state.initialized) {
    store.commit('appInitialized');
  }
});
