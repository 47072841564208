import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import store from '@/store';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import events from '@/plugins/events';
import auth from '@/api/fire/auth';
import presence from '@/api/fire/presence';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresAuth: true, extensionHeight: 48 },
  },
  {
    path: '/welcome',
    name: 'Login',
    component: Login,
    meta: { hideAvatarMenu: true, noAppBar: true },
  },
  {
    path: '/project/:projectId',
    name: 'Project',
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project.vue'),
    meta: { logPresence: true, requiresAuth: true, extensionHeight: 200 },
    props: true,
  },
  {
    path: '/project/:projectId/version/:versionId',
    name: 'Version',
    component: () => import(/* webpackChunkName: "version" */ '@/views/Version.vue'),
    meta: { logPresence: true, requiresAuth: true, extensionHeight: 200 },
    props: true,
  },
  {
    path: '/editor/:projectId/:versionId',
    name: 'PageEditor',
    component: () => import(/* webpackChunkName: "editor" */ '@/views/Editor.vue'),
    meta: {
      logPresence: true, requiresAuth: true, extensionHeight: 200, elevatedAppBar: true,
    },
    props: route => ({
      ...route.params,
      pageId: route.query.pid,
    }),
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/Error.vue'),
  },
  {
    path: '/waitlist',
    name: 'Waitlist',
    component: () => import(/* webpackChunkName: "waitlist" */ '@/views/WaitList.vue'),
  },
];

if (process.env.NODE_ENV === 'development') {
  routes.push({
    path: '/drawer',
    name: 'Drawer',
    component: () => import(/* webpackChunkName: "debug" */ '@/views/Drawer.vue'),
    meta: { requiresAuth: false, noAppBar: true },
  });
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (!requiresAuth) return next();
  if (!store.state.initialized) {
    await new Promise(resolve => events.bus.$on(events.ids.APP_INITIALIZED, resolve));
  }
  if (store.state.auth.signedIn) {
    const isWaitlist = source => source.matched.some(record => record.name === 'Waitlist');
    if (isWaitlist(to) || isWaitlist(from)) {
      // force refresh auth tokens
      await auth.getUserToken(true);
    }
    const token = await auth.getUser().getIdTokenResult();

    // Lab access restriction
    if (document.location.hostname === 'make.senz.io' || document.location.hostname === 'localhost') {
      if (!token.claims.lab) {
        return next({ name: 'Waitlist' });
      }
    } else {
      return next({ name: 'Error' });
    }
    return next();
  }
  return next({ name: 'Login' });
});

router.afterEach(async (to, from, failure) => {
  if (failure) return;
  firebase.analytics().setCurrentScreen(to.name);
  const logPresence = to.matched.some(record => record.meta.logPresence);
  if (logPresence) {
    const { projectId } = to.params;
    await presence.set({
      projectId,
      versionId: to.params.versionId || null,
      pageId: to.query.pid || null,
    });
    await store.dispatch('projects/subscribeToPresence', { projectId });
  } else if (presence.isActive) {
    await presence.off();
    await store.dispatch('projects/unsubscribePresence');
  }
});

events.bus.$on(events.ids.USER_STATE_CHANGE, () => {
  const current = router.currentRoute;
  if (store.state.auth.signedIn && current.name === 'Login') {
    router.push({ name: 'Dashboard' });
  } else if (!store.state.auth.signedIn && current.matched.some(record => record.meta.requiresAuth)) {
    router.push({ name: 'Login' });
  }
});

export default router;
