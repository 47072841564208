import firebase from 'firebase/app';
import auth from '@/api/fire/auth';

export default {
  isActive: false,
  presence: {
    projectId: null,
    versionId: null,
    pageId: null,
  },
  async on() {
    if (this.isActive) return;
    const userId = auth.getUserId();
    const onlineRef = firebase.database().ref(`status/${userId}/online`);
    firebase.database().ref('.info/connected').on('value', async snapshot => {
      const connected = snapshot.val();
      await onlineRef.set(connected);
      if (!connected) return;
      onlineRef.onDisconnect().set(false).then(async () => {
        await onlineRef.set(true);
        const presenceRef = firebase.database().ref(`status/${userId}/presence`);
        await presenceRef.set(this.presence);
      });
    });
    this.isActive = true;
  },
  async off() {
    if (!this.isActive) return;
    const userId = auth.getUserId();
    await firebase.database().ref('.info/connected').off();
    this.isActive = false;
    const onlineRef = firebase.database().ref(`status/${userId}/online`);
    await onlineRef.set(false);
  },
  async set({ projectId, versionId, pageId }) {
    if (!this.isActive) {
      await this.on();
    }
    this.presence = {
      projectId,
      versionId,
      pageId,
    };
    const userId = auth.getUserId();
    const presenceRef = firebase.database().ref(`status/${userId}/presence`);
    await presenceRef.set(this.presence);
  },
};
