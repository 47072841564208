import firebase from 'firebase/app';
import axios from 'axios';

const fn = () => firebase
  .app()
  .functions('europe-west1');

function fetchProfiles({ emails, uids }) {
  const data = { emails, uids };
  return fn().httpsCallable('getUserProfiles')(data);
}

function notifyProjectSharing({ projectId, emails, message }) {
  const data = { projectId, emails, message };
  return fn().httpsCallable('notifyProjectSharing')(data);
}

function unsplashSearch({ keyword, format, color }) {
  const data = { keyword, format, color };
  return fn().httpsCallable('unsplashSearch')(data);
}

async function unsplashDownload({ url }) {
  const data = { url };
  const result = await fn().httpsCallable('unsplashDownload')(data);
  const downloadUrl = result.data.url;
  const dowloadResponse = await axios.get(downloadUrl, {
    responseType: 'blob',
  });
  return dowloadResponse.data;
}

export default {
  fetchProfiles,
  notifyProjectSharing,
  unsplashSearch,
  unsplashDownload,
};
