import db from '@/api/fire/db';
import storage from '@/api/fire/storage';
import auth from '@/api/fire/auth';
import { serverDate } from '@/api/fire/db/utils';

const genId = () => `v${Math.random().toString(36).substr(2, 9)}`;

export default {
  async saveVersionName(ctx, { projectId, versionId, name }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).version(versionId).write({
      name,
    });
    return ctx.dispatch('refreshProject', { projectId });
  },

  async saveVersionDescription(ctx, {
    projectId, versionId, description, tags,
  }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).version(versionId).write({
      tags,
      description,
    });
    return ctx.dispatch('refreshProject', { projectId });
  },

  async saveProjectConfig(ctx, {
    projectId, versionId, config, property, value,
  }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db
      .project(projectId)
      .version(versionId)
      .write(
        property && value
          ? {
            [`config.${property}`]: value,
          }
          : { config },
      );
    return ctx.dispatch('refreshProject', { projectId });
  },

  async saveVersion(ctx, { projectId, versionId, data }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).version(versionId).write(data);
    return ctx.dispatch('refreshProject', { projectId });
  },

  async deleteVersion(ctx, { projectId, versionId }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).version(versionId).delete();
    return ctx.dispatch('refreshProject', { projectId });
  },

  async addPageToVersion(ctx, { projectId, versionId, pageId }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    if (!projectId || !versionId || !pageId) {
      throw new Error('Invalid parameters');
    }
    const pages = ctx.getters.getVersion(projectId, versionId).pages || [];
    await db
      .project(projectId)
      .version(versionId)
      .write({
        pages: [...pages, pageId],
      });
    return ctx.dispatch('refreshProject', { projectId });
  },

  async updateVersionVar(ctx, { projectId, versionId, varItem }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_data')) {
      throw new Error('Unauthorized action');
    }
    await db
      .project(projectId)
      .version(versionId)
      .var(varItem.id)
      .write(varItem);
    return ctx.dispatch('refreshProject', { projectId });
  },

  async deleteVersionVar(ctx, { projectId, versionId, varId }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_data')) {
      throw new Error('Unauthorized action');
    }
    await db
      .project(projectId)
      .version(versionId)
      .var(varId)
      .delete();
    return ctx.dispatch('refreshProject', { projectId });
  },

  async saveVersionResources(ctx, { projectId, versionId, resources }) {
    await db.project(projectId).version(versionId).write({ resources });
    return ctx.dispatch('refreshProject', { projectId });
  },

  async createVersion(ctx, {
    projectId, name, from, projectTarget,
  }) {
    if (!ctx.getters.hasPermission(projectId, 'read_project')) {
      throw new Error('Unauthorized action');
    }
    if (!ctx.getters.hasPermission(projectTarget || projectId, 'edit_project')
      || !ctx.getters.hasPermission(projectTarget || projectId, 'edit_page')
    ) {
      throw new Error('Unauthorized action');
    }
    await ctx.dispatch('senz/refreshGenerators', null, { root: true });

    let versionName = name;
    let resources = [];
    let pages = [];
    let vars = {};
    const uid = auth.getUserId();
    const config = {
      framework: ctx.rootState.senz.generators[0].id,
      theme: ctx.rootState.senz.themes[0].name,
    };
    const versionId = genId();

    if (from) {
      await ctx.dispatch('refreshPages', { projectId, versionId: from });
      const project = ctx.getters.getProject(projectId);
      const origin = project.versions[from];
      if (!versionName) {
        versionName = origin.name;
      }
      resources = origin.resources || [];
      vars = origin.vars || {};
      config.framework = origin.config.framework;
      config.theme = origin.config.theme;
      const pageMap = origin.pages.reduce(
        (map, page) => ({
          [page]: db.project(projectId).pages.doc().id,
          ...map,
        }),
        {},
      );

      const copyPage = async pageId => {
        const newId = pageMap[pageId];
        await ctx.dispatch('refreshFiles', { pageId });
        const page = JSON.parse(JSON.stringify(ctx.state.pages[pageId]));
        const files = ctx.state.pageFiles[pageId];

        Object.values(page.components || {}).forEach(component => {
          component.metas.updated_at = serverDate();
          Object.values(component.actions || {}).forEach(action => {
            (action.steps || []).forEach(step => {
              if (step.actionType === 'navigation') {
                step.page = pageMap[step.page];
              }
            });
          });
        });

        Object.values(page.actions || {}).forEach(action => {
          (action.steps || []).forEach(step => {
            if (step.actionType === 'navigation') {
              step.value = pageMap[step.value];
            }
          });
        });

        await db
          .project(projectTarget || projectId)
          .page(newId)
          .create({
            name: page.name,
            components: page.components || {},
            actions: page.actions || {},
            metas: {
              created_by: uid,
            },
          });

        const copyFile = async filename => {
          const content = await storage.pages().dir(pageId).file(filename).downloadAsBlob();
          await storage.pages().dir(newId).file(filename).upload(content);
        };
        await Promise.all(files.map(filename => copyFile(filename)));
        return newId;
      };
      pages = await Promise.all(origin.pages.map(pageId => copyPage(pageId)));
      if (origin.config.homepageId) {
        config.homepageId = pageMap[origin.config.homepageId];
      }
    }

    await db.project(projectTarget || projectId).versions.create(versionId, {
      name: versionName,
      config,
      resources,
      pages,
      vars,
      metas: {
        created_by: uid,
      },
    });

    return versionId;
  },
};
