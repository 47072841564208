export default {
  roles: [
    {
      name: 'View',
      permissions: ['read_project'],
      scoreValue: 1,
    },
    {
      name: 'Edit',
      permissions: ['read_project', 'edit_data'],
      scoreValue: 3,
    },
    {
      name: 'Draw',
      permissions: ['read_project', 'edit_data', 'edit_page'],
      scoreValue: 7,
    },
    {
      name: 'Publish',
      permissions: ['read_project', 'edit_data', 'edit_page', 'publish_prototypes'],
      scoreValue: 15,
    },
  ],
  ownerPermissions: ['read_project', 'edit_project', 'edit_data', 'edit_page', 'publish_prototypes'],
  findRole(permissions) {
    if (!permissions) return 'Custom';
    let score = 0;
    if (permissions.includes('read_project')) score += 1;
    if (permissions.includes('edit_data')) score += 2;
    if (permissions.includes('edit_page')) score += 4;
    if (permissions.includes('publish_prototypes')) score += 8;
    const foundRole = this.roles.find(role => role.scoreValue === score);
    return foundRole?.name || 'Custom';
  },
  canConfigure(permissions) {
    return permissions?.includes('edit_project') || false;
  },
  getRoleList() {
    return this.roles.map(r => r.name);
  },
  getRole(name) {
    return this.roles.find(r => r.name === name);
  },
};
