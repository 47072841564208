<template lang="pug">
  v-dialog(v-bind="$attrs" v-on="$listeners" :permanent="loading" max-width="380")
    .snz-card
      .snz-row
        .snz-txt-h4 Create a new project
        c-button(variant="text" @click="hide")
          Icon(src="close")
      .snz-txt-body-2 Not sure about your project name! You will be able to rename it later by editing it in your project overview
      c-input(v-model="name" placeholder="Give it a name")
      .snz-row-actions
        c-button(variant="text" @click="hide") Cancel
        c-button(variant="primary" @click="create") Create
</template>

<script>
import firebase from 'firebase/app';
import Icon from '@/views/components/Icon.vue';

export default {
  inheritAttrs: false,
  components: { Icon },
  data: () => ({
    loading: false,
    name: '',
  }),
  methods: {
    hide() {
      this.$emit('input', false);
    },
    async create() {
      this.loading = true;
      const projectId = await this.$store.dispatch('projects/createProject', { name: this.name || 'Untitled Project' });
      firebase.analytics().logEvent('create_project');
      this.$router.push({ name: 'Project', params: { projectId } });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.snz-card {
  padding: 10px 16px;
}
.snz-row,
.snz-row-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.snz-row {
  height: 52px;
  .snz-txt-h4 {
    flex-grow: 1;
  }
}
.snz-txt-body-2 {
  margin-top: 14px;
  margin-bottom: 22px;
}
.snz-row-actions {
  margin-top: 18px;
  height: 48px;
  justify-content: flex-end;
}
</style>
