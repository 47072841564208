<template lang="pug">
  #page-dashboard.pt-6
    create-project-modal(v-model="showCreateModal")
    delete-project-modal(v-model="showDeleteModal" :project="focusedProject")
    share-modal(v-model="showShareModal" :project="focusedProject" @invitationSent="showInviteSent = true")
    //- Toast notification
    v-snackbar(v-model="showInviteSent" :timeout="2000" bottom color="primary")
      span(style="color: white") Invitation sent
      template(v-slot:action)
        v-icon(color="white") mdi-email-check

    portal(to="appbar-left")
      img(src="~@res/images/senz.svg").appbar-logo
    portal(to="appbar-center" v-if="!$vuetify.breakpoint.mobile")
      .appbar-title
        v-text-field(solo flat placeholder="Search" prepend-inner-icon="mdi-magnify"
          hide-details v-model="search" :width="350").mt-1

    portal(to="appbar-extension" v-if="$vuetify.breakpoint.mobile")
      .appbar-extension.px-4
        v-select(:items="filters" v-model="filter" solo hide-details dense)
    portal(to="appbar-extension" v-else)
      .appbar-extension
        v-tabs(v-model="filter" centered)
          v-tab(v-for="entry in filters" :key="entry.value") {{ entry.text }}

    portal(to="appbar-right" v-if="!$vuetify.breakpoint.mobile")
      h5.snz-txt-h5.mr-2
        | Hi, {{ $store.getters['auth/getUser'].displayName }}

    .project-grid(v-if="loading")
      v-skeleton-loader(type="card")
      v-skeleton-loader(type="card")
      v-skeleton-loader(type="card")
    transition-group(v-else name="grid").project-grid
      snz-project-card(v-for="project in filteredProjects" :key="project.id" :project="project"
        @share="focusedProject = project; showShareModal = true"
        @delete="focusedProject = project; showDeleteModal = true")

    v-btn(@click="showCreateModal = true" fixed tile color="primary" bottom right).new-project-fab
      span +
</template>

<script>
import CreateProjectModal from '@/views/components/modal/CreateProject.vue';
import DeleteProjectModal from '@/views/components/modal/DeleteProject.vue';
import ShareModal from '@/views/components/modal/Share.vue';
import SnzProjectCard from '@/views/components/common/ProjectCard.vue';

export default {
  name: 'Dashboard',
  components: {
    CreateProjectModal,
    DeleteProjectModal,
    ShareModal,
    SnzProjectCard,
  },
  data: () => ({
    showCreateModal: false,
    showDeleteModal: false,
    showShareModal: false,
    showInviteSent: false,
    focusedProject: {},
    Actions: false,
    loading: true,
    search: '',
    filter: 1,
    filters: [
      {
        text: 'Recent projects',
        value: 0,
      },
      {
        text: 'My projects',
        value: 1,
      },
      {
        text: 'Shared with me',
        value: 2,
      },
      {
        text: 'Archive',
        value: 3,
      },
    ],
  }),
  async mounted() {
    await this.refresh();
  },
  computed: {
    archivedProjects() {
      return this.$store.getters['auth/getUserData'].archivedProjects || [];
    },
    projects() {
      return this.$store.getters['projects/getProjects'];
    },
    filteredProjects() {
      const { projects } = this;
      if (this.search) {
        return [...projects].filter(p => {
          const searchContent = [p.name || '', ...(p.tags || [])].join(' ').toLowerCase();
          return searchContent.includes(this.search.toLowerCase());
        });
      }
      if (this.filter === 0) {
        return [...projects]
          .filter(p => !this.archivedProjects.includes(p.id))
          .sort((p1, p2) => p2.metas.updated_at.toMillis() - p1.metas.updated_at.toMillis())
          .splice(0, 3);
      }
      if (this.filter === 1) {
        return [...projects].filter(p => !this.archivedProjects.includes(p.id)).filter(p => p.isOwner);
      }
      if (this.filter === 2) {
        return [...projects].filter(p => !this.archivedProjects.includes(p.id)).filter(p => !p.isOwner);
      }
      if (this.filter === 3) {
        return [...projects].filter(p => this.archivedProjects.includes(p.id));
      }
      return projects;
    },
  },
  methods: {
    async refresh() {
      this.loading = true;
      await this.$store.dispatch('auth/refreshUserData');
      await this.$store.dispatch('projects/refreshProjects');
      const projects = this.$store.getters['projects/getProjects'];
      await Promise.all(projects.map(p => this.$store.dispatch('projects/refreshUsers', { projectId: p.id })));
      this.loading = false;
    },
    addProject() {
      this.showCreateModal = true;
    },
    confirmDelete(project) {
      this.focusedProject = project;
      this.showDeleteModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.project-grid {
  margin: 24px auto;
  height: 100%;
  width: 80%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 296px);
  grid-auto-rows: 300px;
  grid-gap: 48px;
}
.appbar-title {
  width: 100%;
  max-width: 360px;
}
.appbar-extension {
  display: flex;
  align-items: center;
  width: 100%;
}
.new-project-fab {
  bottom: 46px !important;
  right: 30px !important;
  width: 52px !important;
  min-width: 52px !important;
  height: 52px !important;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 2px 4px 12px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 1;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 47px;
  }
}
.grid-enter-active,
.grid-move {
  transition: all 0.8s;
}
.grid-enter {
  opacity: 0;
  transform: translateY(20px);
}
.grid-leave-active {
  position: absolute !important;
  opacity: 0;
}
</style>
