import { meta, readDoc } from '../utils';

const ref = parent => parent.collection('projectUsers');
const doc = (parent, id) => ref(parent).doc(id);

export default parent => ({
  collection: {
    create: (email, data) => ref(parent)
      .doc(email)
      .set(meta.create(data)),
    getAll: async () => {
      const results = await ref(parent).get();
      const pages = [];
      results.forEach(res => pages.push(readDoc(res)));
      return pages;
    },
  },
  document: id => {
    if (!id) throw new Error('Invalid document ID');
    return {
      read: () => doc(parent, id)
        .get()
        .then(readDoc),
      delete: () => doc(parent, id).delete(),
      write: data => doc(parent, id).update(meta.update(data)),
      subscribe: callback => doc(parent, id).onSnapshot(snapshot => callback(readDoc(snapshot))),
    };
  },
});
