import { readCollection } from '../utils';

const ref = parent => parent.collection('presences');

export default parent => ({
  collection: {
    subscribe: callback => ref(parent).onSnapshot(snapshot => {
      if (!snapshot.metadata.hasPendingWrites) callback(readCollection(snapshot));
    }),
  },
});
