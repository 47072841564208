<template lang="pug">
  v-hover(v-if="project" v-slot:default="{ hover }" close-delay="200")
    .project-card.snz-card(@click="openDetails()" :class="{ hover }" )
      .project-card_background
        .grey-halo
        .black-halo

      //- Context menu
      v-menu(absolute transition="slide-x-reverse-transition")
        template(v-slot:activator="{ on }")
          v-slide-y-transition
            v-btn(fab :color="hover ? 'black' : 'white'" width="32" height="32" v-on="on"
              @mousedown.stop v-show="true").project-card_menu-button
              Icon(src="dots-vertical" :color="hover ? 'white' : 'black'")
        v-list.page-selector-component_list
          v-list-item(v-if="!isArchived && project.isOwner" @click="$emit('share')")
            v-list-item-icon
              v-icon mdi-share-variant
            v-list-item-content
              v-list-item-title Share
          v-list-item(v-if="isArchived" @click="restore")
            v-list-item-icon
              Icon(src="update")
            v-list-item-content
              v-list-item-title Restore
          v-list-item(v-else @click="archive")
            v-list-item-icon
              Icon(src="update")
            v-list-item-content
              v-list-item-title Archive
          v-list-item(v-if="isArchived && project.isOwner" @click="$emit('delete')")
            v-list-item-icon
              Icon(src="delete-outline")
            v-list-item-content
              v-list-item-title Delete

      //- Card content
      .project-card_title.snz-txt-h5 {{ project.name }}
      .project-card_tagline
        .snz-tag-alt(v-for="tag in tagsHead" :key="tag").mr-2 {{ tag }}
        .snz-tag-alt(v-if="tagsTail.length") +{{ tagsTail.length }}
      .project-card_content
        .project-card_content_desc.snz-txt-body-2 {{ project.description }}
      .project-card_stats
        snz-stat-label(label="Versions" :dark="hover")
          template(v-if="versionCount") {{ versionCount > 9 ? versionCount : `0${versionCount}` }}
        snz-stat-label(label="Published" :dark="hover")
          template(v-if="publishedVersionCount") {{ publishedVersionCount > 9 ? publishedVersionCount : `0${publishedVersionCount}` }}
      .project-card_actions
        Icon(src="update" :height="15" :color="hover ? '#FFF' : 'rgba(0, 0, 0, 0.6)'")
        span.snz-txt-overline.project-card_date {{ project.metas.updated_at.toDate() | date }}
        v-tooltip(bottom v-for="(user, idx) in usersHead" :key="idx")
          template(v-slot:activator="{ on, attrs }")
            span(v-bind="attrs" v-on="on").mr-1.avatar-wrapper
              c-avatar(size="small" v-if="user.profile.photoURL" :src="user.profile.photoURL")
              c-avatar(v-else v-bind="attrs" v-on="on" size="small" :label="(user.profile.displayName || user.email || 'U').charAt(0)")
          span {{ `${user.profile.displayName} (${user.email})` }}
        c-avatar(size="small" v-if="usersTail.length" :label="`+${usersTail.length}`").mr-1
  </template>

<script>
import Icon from '@/views/components/Icon.vue';
import SnzStatLabel from '@/views/components/common/StatLabel.vue';

export default {
  name: 'ProjectCard',
  components: { Icon, SnzStatLabel },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    archivedProjects() {
      return this.$store.getters['auth/getUserData'].archivedProjects || [];
    },
    users() {
      return this.$store.getters['projects/getUsers'](this.project.id) || [];
    },
    publishedVersionCount() {
      if (!this.project?.versions) return 0;
      return Object.values(this.project.versions).filter(v => v.live).length;
    },
    versionCount() {
      if (!this.project?.versions) return 0;
      return Object.values(this.project.versions).length;
    },
    tagsHead() {
      return [...(this.project?.tags || [])].splice(0, 3);
    },
    tagsTail() {
      return [...(this.project?.tags || [])].slice(3);
    },
    usersHead() {
      return [...this.users].splice(0, 2);
    },
    usersTail() {
      return [...this.users].slice(2);
    },
    isArchived() {
      return this.archivedProjects.includes(this.project.id);
    },
  },
  methods: {
    async archive() {
      await this.$store.dispatch('auth/updateUserData', {
        archivedProjects: [...this.archivedProjects, this.project.id],
      });
    },
    async restore() {
      const archivedProjects = [...this.archivedProjects];
      archivedProjects.splice(this.archivedProjects.indexOf(this.project.id), 1);
      await this.$store.dispatch('auth/updateUserData', {
        archivedProjects,
      });
    },
    openDetails() {
      if (this.project.isArchived) return;
      this.$router.push({ name: 'Project', params: { projectId: this.project.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.snz-tag-alt {
  background: #f2f2f2;
  height: 20px;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.6);
  padding: 4px 8px;
  display: inline-block;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  max-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.project-card {
  position: relative;
  height: 300px;
  width: 296px;
  padding: 24px 16px 13px 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  cursor: pointer;

  &_background {
    border-radius: 8px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .black-halo,
    .grey-halo {
      position: absolute;
      height: 40px;
      width: 40px;
      top: -40px;
      right: -40px;
      border-radius: 40px;
    }
    .black-halo {
      background: #000;
      transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
    }
    .grey-halo {
      background: #222;
      transition: transform 1.2s cubic-bezier(0.16, 1, 0.3, 1);
    }
  }

  &_menu-button {
    position: absolute;
    top: 6px;
    right: 6px;
    box-shadow: none;
    z-index: 1;
  }

  &_title,
  &_tagline,
  &_content,
  &_stats,
  &_actions {
    position: relative;
  }

  &_tagline {
    margin-top: 12px;
  }

  &_content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    &_desc {
      max-height: 54px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-wrap;
      color: var(--snz-font-high);
    }
  }

  &_stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }

  &_actions {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    padding: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .avatar-wrapper {
      width: 24px;
      height: 24px;
      display: inline-flex;
    }
  }
  &_date {
    flex-grow: 1;
    padding: 0 10px;
    color: var(--snz-font-high);
  }
  &.hover {
    .project-card_title,
    .project-card_tagline,
    .project-card_content_desc,
    .project-card_date,
    .project-card_actions {
      position: relative;
      color: #fff !important;
    }
    .snz-tag-alt {
      background: rgba(255, 255, 255, 0.1);
      color: rgba(255, 255, 255, 0.3);
    }
    .project-card_background {
      .black-halo {
        transform: scale(11);
        transition: transform 1.5s cubic-bezier(0.16, 1, 0.3, 1);
      }
      .grey-halo {
        transform: scale(30);
        transition: transform 1.8s cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
    .project-card_actions {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      z-index: 1;
    }
  }
}
</style>
