import Vue from 'vue';

const events = {
  bus: new Vue(),
  ids: {
    APP_INITIALIZED: 'APP_INITIALIZED',
    USER_STATE_CHANGE: 'USER_STATE_CHANGE',
    SHORTCUT_ARROW_MOVE: 'ARROW_MOVE',
    SHORTCUT_FULLSCREEN: 'FULLSCREEN',
    SHORTCUT_UNDO: 'UNDO',
    SHORTCUT_REDO: 'REDO',
    SHORTCUT_CLEAR: 'CLEAR',
    SHORTCUT_ERASE: 'ERASE',
    SHORTCUT_CLONE: 'CLONE',
    SHORTCUT_COPY: 'COPY',
    SHORTCUT_PASTE: 'PASTE',
    SHORTCUT_ESCAPE: 'ESCAPE',
    SHORTCUT_TOGGLE_HELP: 'TOGGLE_HELP',
    SHORTCUT_EXPORT: 'EXPORT',
    SHORTCUT_UPLOAD: 'UPLOAD',
    SHORTCUT_RECOGNISE: 'RECOGNISE',
    SHORTCUT_TOGGLE_THEME: 'TOGGLE_THEME',
    SHORTCUT_MODE_DRAW: 'MODE_DRAW',
    SHORTCUT_MODE_SELECT: 'MODE_SELECT',
    SHORTCUT_MODE_ERASE: 'MODE_ERASE',
    SHORTCUT_MODE_MOVE: 'MODE_MOVE',
    SHORTCUT_TOGGLE_BACKGROUND: 'TOGGLE_BACKGROUND',
    SHORTCUT_TOGGLE_FULLSCREEN: 'TOGGLE_FULLSCREEN',
    SHORTCUT_TOGGLE_CANVAS: 'TOGGLE_CANVAS',
    SHORTCUT_SPLIT_VIEW: 'SPLIT_VIEW',
    SHORTCUT_QR_CODE: 'QR_CODE',
    SHORTCUT_SELECT_ALL: 'SELECT_ALL',
    SHORTCUT_TOGGLE_LABELS: 'TOGGLE_LABELS',
    SHORTCUT_TOOGLE_CAMERA_MODAL: 'TOGGLE_CAMERA_MODAL',
  },
};

if (!Vue.prototype.$events) {
  Object.defineProperty(Vue.prototype, '$events', { value: events });
}

export default events;
