<template lang="pug">
  v-dialog(:value="value" @input="$emit('input', $event)" transition="dialog-bottom-transition" max-width="495")
    v-form(ref="form" v-model="valid" lazy-validation)
      .snz-card
        .snz-row
          .snz-txt-h4 Share with your team mate
          c-button(variant="text" @click="hide")
            Icon(src="close")
        .snz-card_content
          v-skeleton-loader(v-if="loadingList" type="list-item-avatar, list-item-avatar").py-4
          v-list(v-else).py-1
            //- New user action
            v-list-item(v-if='project.isOwner')
              v-list-item-content
                .d-flex.flex-nowrap.align-center
                  v-combobox.flex-grow-1(label="Invite collaborator by email" placeholder="Invite collaborator by email" single-line
                    multiple filled v-model="recipients" chips required deletable-chips type="email" @update:search-input="onEmailEdit"
                    :rules="[areEmails]" autofocus hide-details hide-no-data append-icon="")
                    template(v-slot:selection="{ attrs, item, parent, selected }")
                      v-chip(v-if="isEmail(item)" color="#666" outlined
                        close @click:close="parent.onChipInput(item)").white-bg
                        v-avatar(left color="primary" :size="24")
                          span.text-capitalize.white--text {{ item.charAt(0) }}
                        span {{ item }}
                      v-chip(v-else color="error" outlined close @click:close="parent.onChipInput(item)").white-bg
                        v-avatar(left color="error" size="24")
                          v-icon(color="white") mdi-alert-circle-outline
                        span {{ item }}
                  v-select.flex-grow-0(v-if="addState" v-model="role" style="width: 180px"
                    required :items="roleList" solo hide-details).ml-2
                    template(v-slot:item="{ item, on, attrs }")
                      v-list-item(v-bind="attrs" @click.stop="role=item")
                        v-list-item-avatar
                          v-icon(v-if="attrs.inputValue") mdi-check
                        v-list-item-content
                          v-list-item-title.text-capitalize {{ item }}
                    template(v-slot:append-item)
                      v-divider
                      v-list-item(:input-value="configure" isClickable @click="configure = !configure")
                        v-list-item-avatar
                          v-icon(v-if="configure") mdi-check
                        v-list-item-content
                          v-list-item-title Configure
                      v-divider
            v-list-item(v-if="addState" dense)
              v-list-item-content.px-2
                v-checkbox(v-model="notify" label="Notify people" dense hide-details)
            v-list-item(v-if="addState && notify" dense)
              v-list-item-content
                v-textarea(v-model="message" :disabled="!notify" placeholder="Add a custom message")

          //- User list
          .user-list(v-if="!loadingList && !addState")
            snz-project-members(:users="users" :project="project")
        .snz-row-actions(v-if="addState")
          .snz-txt-caption Keep in mind anyone in this group will inherit from the same privileges.
            | You can adjust per user permissions at any time by reopening this window.
          c-button(variant="text" @click="resetState" :disabled="loading") Cancel
          c-button(variant="primary" @click="addUsers" :loading="loading" :disabled="!valid") Share
        .snz-row-actions(v-else)
          .snz-txt-caption You can adjust per user permissions at any time by reopening this window.
          c-button(variant="text" @click="hide" :disabled="loading") Cancel
          c-button(variant="primary" @click="hide" :loading="loading" :disabled="!valid") Done
</template>

<script>
import SnzProjectMembers from '@/views/components/common/ProjectMembers.vue';
import Icon from '@/views/components/Icon.vue';
import userModel from '@/models/user';

export default {
  props: ['value', 'project'],
  components: { Icon, SnzProjectMembers },
  data: () => ({
    loading: false,
    loadingList: false,
    valid: true,
    configure: false,
    role: 'View',
    roleList: userModel.getRoleList(),
    recipients: [],
    notify: true,
    message: '',
    addState: false,
  }),
  watch: {
    async value(visible) {
      if (visible) await this.reloadList();
      else this.resetState();
    },
    async project() {
      if (!this.value) return;
      await this.reloadList();
    },
    recipients(value) {
      if (value?.length) {
        this.addState = true;
      }
    },
  },
  computed: {
    users() {
      return this.$store.getters['projects/getUsers'](this.project.id);
    },
  },
  methods: {
    onEmailEdit(value) {
      if (!this.addState && value?.length > 2) {
        this.addState = true;
      }
    },
    isEmail(value) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    },
    areEmails(values) {
      return values.every(val => this.isEmail(val));
    },
    async reloadList() {
      this.loadingList = true;
      await this.$store.dispatch('projects/refreshUsers', { projectId: this.project.id });
      this.loadingList = false;
    },
    resetState() {
      this.$refs.form.reset();
      this.notify = true;
      this.role = 'View';
      this.configure = false;
      this.addState = false;
    },
    hide() {
      this.$emit('input', false);
    },
    async addUsers() {
      if (this.loading) return;
      this.loading = true;
      const {
        role, configure, notify, message, recipients,
      } = this;
      const permissions = [...(userModel.getRole(role)?.permissions || [])];
      if (configure) {
        permissions.push('edit_project');
      }
      const usedMails = this.users.map(usr => usr.email);
      const promises = recipients
        .map(email => email.toLowerCase())
        .filter(email => !usedMails.includes(email))
        .map(email => this.$store.dispatch('projects/addUser', { projectId: this.project.id, email, permissions }));
      await Promise.all(promises);
      if (notify) {
        this.$store.dispatch('projects/notifyProjectSharing', {
          projectId: this.project.id,
          emails: recipients,
          message,
        });
      }
      this.$store.dispatch('projects/refreshUsers', { projectId: this.project.id });
      this.loading = false;
      this.$emit('invitationSent');
      // show success snackbar
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.snz-card {
  padding: 10px 0;
}
.snz-row,
.snz-row-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
}
.snz-row {
  height: 52px;
  .snz-txt-h4 {
    flex-grow: 1;
  }
}
.snz-card_content {
  margin-top: 12px;
  margin-bottom: 16px;
}
.snz-row-actions {
  height: 48px;
  justify-content: flex-end;
}
.v-chip.v-chip--outlined.v-chip.v-chip.white-bg {
  background-color: white !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.user-list {
  max-height: 496px;
  min-height: 200px;
  scroll-behavior: auto;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 7px;
  padding: 0 20px 0 9px;
  &::-webkit-scrollbar {
    width: 4px;
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: rgba(0, 0, 0, 0.7);
  }
}
</style>
