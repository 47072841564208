import db from '@/api/fire/db';
import fn from '@/api/fire/functions';
import userModel from '@/models/user';

export default {
  async refreshUsers(ctx, { projectId }) {
    const project = ctx.getters.getProject(projectId);
    const users = await db.project(projectId).users.getAll();
    await ctx.dispatch(
      'auth/fetchProfiles',
      {
        emails: users.map(usr => usr.email),
        uids: project.owners,
      },
      { root: true },
    );
    const projectOwners = project.owners.map(userId => {
      const profile = ctx.rootGetters['auth/getProfile'](userId);
      return {
        isOwner: true,
        userId,
        role: 'owner',
        email: profile.email,
        profile,
        canConfigure: true,
        permissions: userModel.ownerPermissions,
      };
    });
    const projectUsers = users.map(user => {
      const profile = ctx.rootGetters['auth/getProfileByEmail'](user.email);
      return {
        isOwner: false,
        email: user.email,
        role: userModel.findRole(user.permissions),
        userId: profile?.uid,
        profile,
        canConfigure: userModel.canConfigure(user.permissions),
        permissions: user.permissions || [],
      };
    });
    ctx.commit('setProjectUsers', { projectId, users: [...projectOwners, ...projectUsers] });
  },

  async addUser(ctx, { projectId, email, permissions }) {
    if (!ctx.getters.getProject(projectId).isOwner) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).users.create(email, { email, permissions });
  },

  async updateUserRights(ctx, { projectId, email, permissions }) {
    if (!ctx.getters.getProject(projectId).isOwner) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).user(email).write({ permissions });
  },

  async deleteUser(ctx, { projectId, email }) {
    if (!ctx.getters.getProject(projectId).isOwner) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).user(email).delete();
    return ctx.dispatch('refreshUsers', { projectId });
  },

  async notifyProjectSharing(ctx, { projectId, emails, message }) {
    if (!ctx.getters.getProject(projectId).isOwner) {
      throw new Error('Unauthorized action');
    }
    await fn.notifyProjectSharing({ projectId, emails, message });
  },
};
