import Vue from 'vue';

export default {
  loading(ctx, { loading } = { loading: true }) {
    ctx.loading = loading;
  },
  setProjects(ctx, { projects }) {
    ctx.projects = {};
    projects.forEach(project => {
      Vue.set(ctx.projects, project.id, project);
    });
  },
  setProject(ctx, { projectId, project }) {
    Vue.set(ctx.projects, projectId, project);
  },
  setProjectUsers(ctx, { projectId, users }) {
    Vue.set(ctx.projectUsers, projectId, users);
  },
  setProjectInvites(ctx, { projectId, users }) {
    Vue.set(ctx.projectInvites, projectId, users);
  },
  setPrototypes(ctx, { prototypes }) {
    prototypes.forEach(proto => {
      Vue.set(ctx.prototypes, proto.id, proto);
    });
  },
  setPresences(ctx, { presences }) {
    Vue.set(ctx, 'presences', presences);
  },
  setPages(ctx, { pages }) {
    pages.forEach(page => {
      Vue.set(ctx.pages, page.id, page);
    });
  },
  setPage(ctx, { page }) {
    Vue.set(ctx.pages, page.id, page);
  },
  setPageFiles(ctx, { pageId, fileIds }) {
    Vue.set(ctx.pageFiles, pageId, fileIds);
  },
  setFiles(ctx, { files }) {
    files.forEach(file => {
      Vue.set(ctx.files, file.id, file);
    });
  },
  setFile(ctx, { file }) {
    Vue.set(ctx.files, file.id, file);
  },
  setPageThumb(ctx, { pageId, url }) {
    Vue.set(ctx.pageThumbs, pageId, url);
  },
  setDownload(ctx, { fileId, download }) {
    Vue.set(ctx.fileDownloads, fileId, download);
  },
};
