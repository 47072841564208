import db from '@/api/fire/db';
import storage from '@/api/fire/storage';
import auth from '@/api/fire/auth';
import { serverDate } from '@/api/fire/db/utils';

const subs = {};

export default {
  async refreshPages(ctx, { projectId, versionId }) {
    const version = ctx.getters.getVersion(projectId, versionId);
    const pages = await db.project(projectId).pages.getList(version.pages);

    ctx.commit('setPages', { pages });

    const promises = pages.map(page => ctx.dispatch('refreshFiles', { pageId: page.id }));
    return Promise.all(promises);
  },

  async refreshPage(ctx, { projectId, pageId }) {
    const page = await db.project(projectId).page(pageId).read();
    ctx.commit('setPage', { pageId, page });
    return ctx.dispatch('refreshFiles', { pageId });
  },

  async subscribeToPage(ctx, { projectId, pageId }) {
    if (subs[pageId]) subs[pageId]();
    subs[pageId] = await db
      .project(projectId)
      .page(pageId)
      .subscribe(page => {
        if (!page) return;
        ctx.commit('setPage', { pageId, page });
      });
  },
  unsubscribePage(ctx, { pageId }) {
    if (subs[pageId]) subs[pageId]();
  },

  async saveComponentList(ctx, { projectId, pageId, components }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_data')) {
      throw new Error('Unauthorized action');
    }
    Object.values(components).forEach(comp => {
      if (!comp.metas) comp.metas = {};
      comp.metas.updated_at = serverDate;
    });
    await db
      .project(projectId)
      .page(pageId)
      .updateComponents({
        components,
        'metas.predicted_at': serverDate,
      });
    if (subs[pageId]) return Promise.resolve();
    return ctx.dispatch('refreshPage', { projectId, pageId });
  },

  async updateComponent(ctx, { projectId, pageId, component }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_data')) {
      throw new Error('Unauthorized action');
    }
    const componentCopy = JSON.parse(JSON.stringify(component));
    if (!componentCopy.metas) componentCopy.metas = {};
    componentCopy.metas.updated_at = serverDate;
    await db
      .project(projectId)
      .page(pageId)
      .write({ [`components.${component.id}`]: componentCopy });
    if (subs[pageId]) return Promise.resolve();
    return ctx.dispatch('refreshPage', { projectId, pageId });
  },

  async deleteComponentAction(ctx, {
    projectId, pageId, componentId, actionId,
  }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_data')) {
      throw new Error('Unauthorized action');
    }
    await db
      .project(projectId)
      .page(pageId)
      .component(componentId)
      .action(actionId)
      .delete();
    if (subs[pageId]) return Promise.resolve();
    return ctx.dispatch('refreshPage', { projectId, pageId });
  },

  async deletePageAction(ctx, {
    projectId, pageId, actionId,
  }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_data')) {
      throw new Error('Unauthorized action');
    }
    await db
      .project(projectId)
      .page(pageId)
      .action(actionId)
      .delete();
    if (subs[pageId]) return Promise.resolve();
    return ctx.dispatch('refreshPage', { projectId, pageId });
  },

  async updatePageActions(ctx, { projectId, pageId, actions }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_data')) {
      throw new Error('Unauthorized action');
    }
    await db
      .project(projectId)
      .page(pageId)
      .write({ actions });
    if (subs[pageId]) return Promise.resolve();
    return ctx.dispatch('refreshPage', { projectId, pageId });
  },

  /**
   * Refresh files for a page
   */
  async refreshFiles(ctx, { pageId }) {
    const files = await storage.pages().dir(pageId).listAll();
    ctx.commit('setPageFiles', { pageId, fileIds: files.items.map(f => f.name) });
    ctx.commit('setFiles', { files: files.items });
  },

  async refreshPageThumb(ctx, { pageId, force }) {
    if (!force && ctx.state.pageThumbs[pageId]) return;
    try {
      const url = await storage.pages().dir(pageId).file('drawing.png').getDownloadUrl();
      ctx.commit('setPageThumb', { pageId, url });
    } catch (error) {
      // Image not existing
    }
  },

  async downloadPageSVG(ctx, { pageId }) {
    return storage.pages().dir(pageId).file('drawing.svg').downloadAsText();
  },

  async downloadPrediction(ctx, { pageId }) {
    const text = await storage.pages().dir(pageId).file('prediction.json').downloadAsText();
    return text ? JSON.parse(text) : null;
  },

  async savePageScreenshot(ctx, { projectId, pageId, file }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_page')) {
      throw new Error('Unauthorized action');
    }
    await storage.pages().dir(pageId).file(file.name).upload(file.blob, file.metas);
  },

  async savePageFiles(ctx, { projectId, pageId, files }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_page')) {
      throw new Error('Unauthorized action');
    }
    const promises = files.map(file => storage.pages().dir(pageId).file(file.name).upload(file.blob, file.metas));
    await Promise.all(promises);
    await ctx.dispatch('refreshPageThumb', { pageId, force: true });
    return ctx.dispatch('refreshFiles', { pageId });
  },

  async createPage(ctx, { projectId, versionId, name }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_page') || !ctx.getters.hasPermission(projectId, 'edit_project')) {
      throw new Error('Unauthorized action');
    }
    const uid = auth.getUserId();
    const page = await db.project(projectId).pages.create({
      name: name || 'Untitled page',
      metas: {
        created_by: uid,
      },
    });
    await ctx.dispatch('addPageToVersion', { projectId, versionId, pageId: page.id });
    await ctx.dispatch('refreshPages', { projectId, versionId });
    return page.id;
  },

  async savePageName(ctx, { projectId, pageId, name }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_page')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).page(pageId).write({
      name,
    });
    return ctx.dispatch('refreshPage', { projectId, pageId });
  },

  async copyPage(ctx, {
    projectId, versionId, pageId, name = '',
  }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_page')) {
      throw new Error('Unauthorized action');
    }
    await ctx.dispatch('refreshPage', { projectId, pageId });
    await ctx.dispatch('refreshFiles', { pageId });

    const uid = auth.getUserId();
    const original = ctx.state.pages[pageId];
    const page = await db.project(projectId).pages.create({
      name: name || `Copy of ${original.name}`,
      components: original.components || {},
      metas: {
        created_by: uid,
      },
    });

    const files = ctx.state.pageFiles[pageId];
    const copyFile = async filename => {
      const content = await storage.pages().dir(pageId).file(filename).downloadAsBlob();
      await storage.pages().dir(page.id).file(filename).upload(content);
    };
    await Promise.all(files.map(filename => copyFile(filename)));

    if (versionId) {
      await ctx.dispatch('addPageToVersion', {
        projectId,
        versionId,
        pageId: page.id,
      });
    }
    await ctx.dispatch('refreshPage', { projectId, pageId: page.id });
    return page.id;
  },

  async deletePage(ctx, { projectId, versionId, pageId }) {
    if (!ctx.getters.hasPermission(projectId, 'edit_page')) {
      throw new Error('Unauthorized action');
    }
    await db.project(projectId).page(pageId).delete();
    const pages = ctx.getters.getVersion(projectId, versionId).pages || [];

    await db
      .project(projectId)
      .version(versionId)
      .write({
        pages: pages.filter(id => id !== pageId),
      });
    return ctx.dispatch('refreshProject', { projectId });
  },
};
