<template lang="pug">
  i.icon
    component(v-if="icon" :is="icon" :style="style")
</template>

<script>
export default {
  name: 'Icon',
  props: {
    src: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: '#5F6368',
    },
    opacity: {
      type: Number,
      default: 1,
    },
    height: {
      type: Number,
      default: 24,
    },
    rotate: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    icon: null,
  }),
  watch: {
    src: {
      immediate: true,
      handler(src) {
        // eslint-disable-next-line prefer-template
        this.icon = () => import('@res/images/icons/' + src + '.svg?inline');
      },
    },
  },
  computed: {
    style() {
      return {
        width: `${this.height}px`,
        height: `${this.height}px`,
        color: this.color,
        opacity: this.opacity,
        transform: `rotate(${this.rotate}deg)`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    svg {
      transition: .5s ease transform;
    }
    svg, path {
      fill: currentColor
    }
  }
</style>
