import firebase from 'firebase/app';

export const serverDate = () => firebase.firestore.FieldValue.serverTimestamp();

export const timestamp = fromDate => firebase.firestore.Timestamp.fromDate(fromDate);

export const date = fromTimestamp => {
  const time = new firebase.firestore.Timestamp(fromTimestamp.seconds, fromTimestamp.nanoseconds);
  return time.toDate();
};

const removeEmpty = obj => {
  Object.keys(obj).forEach(key => {
    if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
    else if (obj[key] === undefined) delete obj[key];
  });
  return obj;
};

/**
 * Flatten a js deep object into a dot notation
 * EG  { foo: { bar: true } } => { 'foo.bar': true }
 */
const flattenSyntax = (data, prefix) => Object.keys(data).reduce((result, key) => {
  const value = data[key];
  const prefixedKey = `${prefix ? `${prefix}.` : ''}${key}`;
  const isObject = value && typeof value === 'object' && !Array.isArray(value);
  const isEmptyObject = isObject && Object.keys(value).length === 0;
  const isFunction = !isObject && typeof value === 'function';
  let flattenResult;
  if (isEmptyObject) {
    flattenResult = {
      ...result,
      [prefixedKey]: {},
    };
  } else if (isObject) {
    flattenResult = {
      ...result,
      ...flattenSyntax(value, prefixedKey),
    };
  } else if (isFunction) {
    flattenResult = {
      ...result,
      [prefixedKey]: value(),
    };
  } else {
    flattenResult = {
      ...result,
      [prefixedKey]: value,
    };
  }
  return flattenResult;
}, {});

export const meta = {
  create: data => ({
    ...removeEmpty(data),
    metas: {
      ...(data.metas || {}),
      created_at: serverDate(),
      updated_at: serverDate(),
      deleted: false,
    },
  }),
  createInto: (data, prefix) => flattenSyntax(
    {
      ...removeEmpty(data),
      metas: {
        ...(data.metas || {}),
        created_at: serverDate,
        updated_at: serverDate,
        deleted: false,
      },
    },
    prefix,
  ),
  update: (data, prefix) => flattenSyntax(
    {
      ...removeEmpty(data),
      metas: {
        updated_at: serverDate,
      },
    },
    prefix,
  ),
  delete: prefix => flattenSyntax(
    {
      metas: {
        deleted_at: serverDate,
        deleted: true,
      },
    },
    prefix,
  ),
  deleteField: (field, prefix) => flattenSyntax(
    {
      [field]: firebase.firestore.FieldValue.delete,
    },
    prefix,
  ),
};

export const readDoc = doc => {
  if (!doc.exists) return null;
  const data = doc.data();
  return {
    id: doc.id,
    ...data,
  };
};

export const readCollection = collection => {
  const result = [];
  collection.forEach(doc => {
    result.push(readDoc(doc));
  });
  return result;
};

export default {
  serverDate,
  timestamp,
  date,
};
