import axios from 'axios';

const baseUrl = process.env.VUE_APP_BASE_TITAN_URL;

async function predict({ uid, img }) {
  const formData = new FormData();
  formData.append('image', img);
  const response = await axios.post(`${baseUrl}/detection?columns=12&row=64&score=0.9&uid=${uid}`, formData);
  return response.data;
}

async function exportArchive({ framework, uid }) {
  const response = await fetch(`${baseUrl}/${framework}-export?uid=${uid}`, { method: 'post' });
  return response.arrayBuffer();
}

async function convertToSvg(file) {
  const formData = new FormData();
  formData.append('image', file);
  const response = await axios.post('https://titan.senz.io/document-scanner/svg', formData);
  return response.data;
}

export default {
  predict,
  exportArchive,
  convertToSvg,
};
