<template lang="pug">
  v-dialog(v-bind="$attrs" v-on="$listeners" :permanent="loading" max-width="380")
    .snz-card
      .snz-row
        .snz-txt-h4 {{ name }} deletion
        c-button(variant="text" @click="hide")
          Icon(src="close")
      .snz-txt-body-2 You are about to delete this project and every file associated. This action cannot be undone. Continue?
      .snz-row-actions
        c-button(variant="text" @click="hide") Cancel
        c-button(variant="primary" @click="deleteProject" :loading="loading") Delete
</template>

<script>
import firebase from 'firebase/app';
import Icon from '@/views/components/Icon.vue';

export default {
  inheritAttrs: false,
  components: { Icon },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    name() {
      return this.project?.name || '';
    },
  },
  methods: {
    hide() {
      this.$emit('input', false);
    },
    async deleteProject() {
      this.loading = true;
      await this.$store.dispatch('projects/deleteProject', { projectId: this.project.id });
      firebase.analytics().logEvent('delete_project');
      this.loading = false;
      this.$emit('onDelete');
      this.hide();
    },
  },
};
</script>
<style lang="scss" scoped>
.snz-card {
  padding: 10px 16px;
}
.snz-row,
.snz-row-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.snz-row {
  height: 52px;
  .snz-txt-h4 {
    flex-grow: 1;
  }
}
.snz-txt-body-2 {
  margin-top: 14px;
  margin-bottom: 22px;
}
.snz-row-actions {
  margin-top: 18px;
  height: 48px;
  justify-content: flex-end;
}
</style>
