import { meta, readDoc } from '../utils';

const ref = parent => parent.collection('projectPrototypes');
const doc = (parent, id) => ref(parent).doc(id);

export default parent => ({
  collection: {
    create: (id, data) => ref(parent).doc(id).set(meta.create(data)),
    getAll: async () => {
      const results = await ref(parent).get();
      const protos = [];
      results.forEach(res => protos.push(readDoc(res)));
      return protos;
    },
  },
  document: id => {
    if (!id) throw new Error('Invalid document ID');
    return {
      read: () => doc(parent, id).get().then(readDoc),
      write: data => doc(parent, id).update(meta.update(data)),
    };
  },
});
