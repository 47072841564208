import Vue from 'vue';

const loaded = false;
Vue.component('font-awesome-icon', async () => {
  if (!loaded) {
    const { library } = await import('@fortawesome/fontawesome-svg-core');
    const { fas } = await import('@fortawesome/free-solid-svg-icons');
    const { fab } = await import('@fortawesome/free-brands-svg-icons');
    const { far } = await import('@fortawesome/free-regular-svg-icons');
    library.add(fas, fab, far);
  }
  const { FontAwesomeIcon } = await import('@fortawesome/vue-fontawesome');
  return FontAwesomeIcon;
});

Vue.prototype.$iconLoaded = loaded;
