import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import events from '@/plugins/events';
import auth from '@/api/fire/auth';
import Vue from 'vue';

export default router => {
  Sentry.init({
    Vue,
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_VERSION,
    environment: process.env.VUE_APP_MODE,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['make.senz.io', /^\//],
      }),
    ],
    tracesSampleRate: 1.0,
  });

  events.bus.$on(events.ids.USER_STATE_CHANGE, () => {
    if (auth.isLoggedIn()) {
      const user = auth.getUser();
      Sentry.setUser({ email: user.email, username: user.displayName, id: user.uid });
    } else {
      Sentry.setUser(null);
    }
  });
};
