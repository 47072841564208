import firebase from 'firebase/app';
import projectPages from './pages';
import projectUsers from './users';
import projectPresences from './presences';
import projectInvites from './invites';
import projectPrototypes from './prototypes';
import { meta, readDoc } from '../utils';

const ref = () => firebase.firestore().collection('projects');
const doc = id => ref().doc(id);

export default {
  collection: {
    create: data => ref().add(meta.create(data)),
    findUserProjects: async (uid, email) => {
      const results = await ref()
        .where('owners', 'array-contains', uid)
        .get();
      const projects = [];
      results.forEach(res => projects.push({
        ...readDoc(res),
        isOwner: true,
      }));

      const projectSnaps = await firebase
        .firestore()
        .collectionGroup('projectUsers')
        .where('email', '==', email)
        .get();
      const promises = [];
      projectSnaps.forEach(snapshot => promises.push(
        (async () => {
          const { permissions } = snapshot.data();
          const projectSnapshot = await snapshot.ref.parent.parent.get();
          return {
            ...readDoc(projectSnapshot),
            permissions,
            isOwner: false,
          };
        })(),
      ));
      await Promise.all(promises).then(sharedProjects => projects.push(...sharedProjects));
      return projects
        .filter(project => project.metas)
        .map(project => {
          Object.keys(project.versions || {}).forEach(versionId => {
            if (project.versions[versionId].metas.deleted) {
              delete project.versions[versionId];
            }
          });
          return project;
        }).filter(project => !project.metas.deleted);
    },
  },
  document: id => {
    if (!id) throw new Error('Invalid document ID');
    return {
      read: (uid, email) => doc(id)
        .get()
        .then(readDoc)
        .then(async project => {
          if (project.owners.includes(uid)) {
            project.isOwner = true;
          } else {
            const { permissions } = await projectUsers(doc(id))
              .document(email)
              .read();
            project.isOwner = false;
            project.permissions = permissions;
          }
          Object.keys(project.versions || {}).forEach(versionId => {
            if (project.versions[versionId].metas.deleted) {
              delete project.versions[versionId];
            }
          });
          return project;
        }),
      delete: () => doc(id).update(meta.delete()),
      write: data => doc(id).update(meta.update(data)),
      subscribe: callback => doc(id).onSnapshot(snapshot => callback(readDoc(snapshot))),
      pages: projectPages(doc(id)).collection,
      page: pageId => projectPages(doc(id)).document(pageId),
      users: projectUsers(doc(id)).collection,
      user: userEmail => projectUsers(doc(id)).document(userEmail),
      invites: projectInvites(doc(id)).collection,
      invite: userEmail => projectInvites(doc(id)).document(userEmail),
      prototypes: projectPrototypes(doc(id)).collection,
      prototype: protoId => projectPrototypes(doc(id)).document(protoId),
      presences: projectPresences(doc(id)).collection,
      versions: {
        create: (versionId, data) => doc(id).update(meta.createInto(data, `versions.${versionId}`)),
      },
      version: versionId => ({
        write: data => doc(id).update(meta.update(data, `versions.${versionId}`)),
        delete: () => doc(id).update(meta.delete(`versions.${versionId}`)),
        var: varId => ({
          write: data => doc(id).update(meta.update(data, `versions.${versionId}.vars.${varId}`)),
          delete: () => doc(id).update(meta.deleteField(varId, `versions.${versionId}.vars`)),
        }),
      }),
    };
  },
};
