import firebase from 'firebase/app';
import { meta, readDoc } from '../utils';

const ref = parent => parent.collection('pages');
const doc = (parent, id) => ref(parent).doc(id);

export default parent => ({
  collection: {
    doc: () => ref(parent).doc(),
    create: data => ref(parent).add(meta.create(data)),
    getAll: async () => {
      const results = await ref(parent).get();
      const pages = [];
      results.forEach(res => pages.push(readDoc(res)));
      return pages;
    },
    getList: async ids => {
      if (!ids || !ids.length) return [];
      const pages = [];
      const promises = [];
      const chunksCount = Math.ceil(ids.length / 10);
      for (let i = 0; i < chunksCount; i += 1) {
        const chunk = [...ids].splice(i * 10, 10);
        const promise = ref(parent)
          .where(firebase.firestore.FieldPath.documentId(), 'in', chunk)
          .get()
          .then(results => results.forEach(res => pages.push(readDoc(res))));
        promises.push(promise);
      }
      await Promise.all(promises);
      return pages;
    },
  },
  document: id => {
    if (!id) throw new Error('Invalid document ID');
    return {
      create: data => doc(parent, id).set(meta.create(data)),
      read: () => doc(parent, id).get().then(readDoc),
      delete: () => doc(parent, id).delete(),
      write: data => doc(parent, id).update(meta.update(data)),
      action: actionId => ({
        delete: () => doc(parent, id).update(meta.deleteField(actionId, 'actions')),
      }),
      updateComponents: async data => {
        const pageRef = doc(parent, id);
        await pageRef.update({ components: {} });
        return pageRef.update(meta.update(data));
      },
      component: componentId => ({
        action: actionId => ({
          delete: () => doc(parent, id).update(meta.deleteField(actionId, `components.${componentId}.actions`)),
        }),
      }),
      subscribe: callback => doc(parent, id).onSnapshot(snapshot => {
        if (!snapshot.metadata.hasPendingWrites) callback(readDoc(snapshot));
      }),
    };
  },
});
