<template lang="pug">
  v-list.snz-project-members.pa-0
    v-list-item(v-for="user in users" two-line :key="user.email")
      snz-avatar(size="36" :user="user" list-item)
      v-list-item-content
        v-list-item-title.snz-txt-h6 {{ user.profile.displayName  }}
        v-list-item-subtitle.snz-txt-overline {{ user.email }}
      v-list-item-action(v-if="user.isOwner")
        span.snz-txt-btn Owner
      v-list-item-action(v-else)
        v-select(:disabled="!project.isOwner" :value="user.role" required :items="roleList" hide-details).ml-2
          template(v-slot:item="{ item, on, attrs }")
            v-list-item(v-bind="attrs" @click.stop="setRole(user, item)").snz-project-members--list-item
              v-list-item-avatar(size="24")
                v-icon(v-if="attrs.inputValue") mdi-check
              v-list-item-content
                v-list-item-title.text-capitalize {{ item }}
          template(v-slot:append-item)
            v-divider
            v-list-item(:input-value="user.canConfigure" isClickable @click="toggleConfigureRight(user)").snz-project-members--list-item
              v-list-item-avatar(size="24")
                v-icon(v-if="user.canConfigure") mdi-check
              v-list-item-content
                v-list-item-title Configure
            v-list-item(isClickable @click="remove(user)").snz-project-members--list-item
              v-list-item-avatar(size="24")
              v-list-item-content
                v-list-item-title Remove
  </template>

<script>
import userModel from '@/models/user';
import SnzAvatar from '@/views/components/common/Avatar.vue';

export default {
  name: 'ProjectMembers',
  components: { SnzAvatar },
  props: {
    project: {
      type: Object,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      roleList: ['View', 'Edit', 'Draw', 'Publish'],
    };
  },
  methods: {
    async updatePermissions(user) {
      const permissions = [...userModel.roles.find(role => role.name === user.role)?.permissions] || [];
      if (user.canConfigure) {
        permissions.push('edit_project');
      }
      await this.$store.dispatch('projects/updateUserRights', {
        projectId: this.project.id,
        email: user.email,
        permissions,
      });
    },
    async toggleConfigureRight(user) {
      user.canConfigure = !user.canConfigure;
      await this.updatePermissions(user);
    },
    async setRole(user, role) {
      user.role = role;
      await this.updatePermissions(user);
    },
    async remove(user) {
      await this.$store.dispatch('projects/deleteUser', {
        projectId: this.project.id,
        email: user.email,
      });
    },
  },
};
</script>
<style lang="scss">
.snz-project-members {
  background: none !important;
  .v-list-item {
    padding: 0;
  }
  .v-select {
    width: min-content !important;
    .v-input__slot:before {
      display: none;
    }
    .v-select__selections {
      font-family: Roboto, sans-serif !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      letter-spacing: 0.05em !important;
      text-transform: capitalize !important;
    }
    .v-input__icon {
      line-height: 24px !important;
      color: rgba(0, 0, 0, 0.8);
    }
    .v-select__selection--comma {
      text-overflow: unset !important;
      max-width: 100%;
    }
  }
}
.snz-project-members--list-item {
  &.v-list-item--active::before {
    opacity: 0 !important;
  }
  &:hover::before,
  &.v-list-item--active:hover::before {
    opacity: 0.04 !important;
  }
  .v-list-item__avatar {
    height: 24px;
    width: 24px;
  }
}
</style>
