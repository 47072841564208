<template lang="pug">
  v-overlay(:value="show" opacity="0.8")
    .shortcut-content
      h1 Shortcuts
      v-row
        v-col.col-12
          .headline App
        v-col.col-12
          //- TOGGLE_HELP
          .shortcut-line
            .keys #[span Shift]#[span ?] or #[span Cmd]#[span /]
            .action Help
          //- TOGGLE_FULLSCREEN
          .shortcut-line
            .keys #[span f]
            .action Toggle fullscreen
      v-row
        v-col.col-12
          .headline Editor
        v-col.col-lg-6.col-12
          //- MODE_DRAW
          .shortcut-line
            .keys #[span 1] or #[span p]
            .action Mode draw
          //- MODE_SELECT
          .shortcut-line
            .keys #[span 2] or #[span v]
            .action Mode select
          //- MODE_ERASE
          .shortcut-line
            .keys #[span 3] or #[span e]
            .action Mode erase
          //- MODE_MOVE
          .shortcut-line
            .keys #[span 4] or #[span m]
            .action Mode move
          //- TOGGLE_BACKGROUND
          .shortcut-line
            .keys #[span 5] or #[span g]
            .action Toggle background
          //- ERASE
          .shortcut-line
            .keys #[span ⌫] or #[span DEL]
            .action Erase
          //- CLONE
          .shortcut-line
            .keys #[span Ctrl]#[span d] or #[span Cmd]#[span d]
            .action Clone
          //- ESCAPE
          .shortcut-line
            .keys #[span Esc]
            .action Cancel selection
          //- SELECT
          .shortcut-line
            .keys #[span Ctrl]#[span a] or #[span Cmd]#[span a]
            .action Select all
        v-col
          //- RECOGNISE
          .shortcut-line
            .keys #[span 0]
            .action Recognise
          //- UNDO
          .shortcut-line
            .keys #[span Crtl]#[span z] or #[span Cmd]#[span z]
            .action Undo
          //- REDO
          .shortcut-line
            .keys #[span Crtl]#[span shift]#[span z] or #[span Cmd]#[span shift]#[span z]
            .action Redo
          //- CLEAR
          .shortcut-line
            .keys #[span Ctrl]#[span Alt]#[span ⌫] or #[span Cmd]#[span Alt]#[span ⌫]
            .action Clear
          //- TOGGLE_THEME
          .shortcut-line
            .keys #[span t]
            .action Toggle theme
          //- TOGGLE_CANVAS
          .shortcut-line
            .keys #[span Shift]#[span C]
            .action Toggle Canvas
          //- QR_CODE
          .shortcut-line
            .keys #[span Shift]#[span Q]
            .action Show QR code
          //- EXPORT
          .shortcut-line
            .keys #[span Ctrl]#[span e] or #[span Cmd]#[span e]
            .action Export
          //- UPLOAD
          .shortcut-line
            .keys #[span Ctrl]#[span i] or #[span Cmd]#[span i]
            .action Upload
</template>

<script>
export default {
  data: () => ({
    show: false,
  }),
  beforeDestroy() {
    const events = this.$events;
    events.bus.$off(events.ids.SHORTCUT_TOGGLE_HELP);
    events.bus.$off(events.ids.SHORTCUT_ESCAPE);
  },
  mounted() {
    const events = this.$events;
    events.bus.$on(events.ids.SHORTCUT_TOGGLE_HELP, () => {
      this.show = !this.show;
    });
    events.bus.$on(events.ids.SHORTCUT_ESCAPE, () => {
      if (this.show) {
        this.show = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.shortcut-content {
  margin-top: 64px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  width: 100vw;
  padding: 2rem;
}
.shortcut-line {
  display: flex;
  flex-direction: row;
  margin: 1rem .5rem 1rem 0;
  padding: .5rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  .keys {
    flex-grow: 1;
    font-weight: 700 !important;
    span {
      font-weight: 900 !important;
      text-transform: uppercase;
      height: 30px;
      padding: 5px 10px;
      background: #FCFCFC;
      color: black;
      border-radius: 2px;
      border: 1px solid black;
    }
  }
  .action {
    font-weight: 300 !important;
  }
}
</style>
