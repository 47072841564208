import projects from './projects';
import generators from './generators';
import users from './users';

export default {
  projects: projects.collection,
  project: id => projects.document(id),
  generators: generators.collection,
  generator: id => generators.document(id),
  user: id => users.document(id),
};
